export interface DocumentQuestionDTO {
    question: string;
    questionIdentifier: string;
    answer: string;
    sectionIdentifier: string;
    createdBy: string;
    createdAt: Date;
    updatedBy: string;
    lastUpdated: Date;
    assignee: string;
}

export interface DocumentSectionDTO {
    name: string;
    questions: DocumentQuestionDTO[];
    sectionIdentifier: string;
    index: number;
}

export const DefaultDocumentSection: DocumentSectionDTO = {
    name: '',
    questions: [],
    sectionIdentifier: '',
    index: 0
}

export interface DocumentDTO {
    documentIdentifier: string;
    name: string;
    createdOn: Date;
    lastUpdated: Date;
    status: string;
    statusUpdatedBy: string;
    ownerOne: string;
    ownerTwo: string;
    industry: string;
    segment: string;
    questions: DocumentQuestionDTO[];
    sections: DocumentSectionDTO[];
}

export const DefaultDocument: DocumentDTO = {
    documentIdentifier: '',
    name: '',
    createdOn: new Date(),
    lastUpdated: new Date(),
    status: '',
    statusUpdatedBy: '',
    ownerOne: '',
    ownerTwo: '',
    industry: '',
    segment: '',
    questions: [],
    sections: []
}