import React, { Component } from "react";
import Loading from "../../components/loading";
import contentLibraryApi from '../../services/contentLibraryApi';
import { RouteComponentProps } from 'react-router-dom'

type IframeProps = {
    src: string,
    height: string,
    width: string
}

type MatchParams =  {
    documentId: string;
}

type ViewDocumentSate = {
    Url: string
}

class Iframe extends Component<IframeProps> {
    render() {
        return (
            <div>
                <iframe title="docView" src={this.props.src} height={this.props.height} width={this.props.width} />
            </div>
        )
    }
}

export default class ViewDocument extends Component<RouteComponentProps<MatchParams>, ViewDocumentSate> {
    state: ViewDocumentSate = {
        Url: ''
    }

    componentDidMount() {
        this.getDocumentUrl().then((data) => {
            console.log('Loaded document URL');
        });
    }

    async getDocumentUrl() {
        const documentUrl = await contentLibraryApi.getDocumentUrl(this.props.match.params.documentId);

        this.setState({
            Url: 'https://docs.google.com/viewer?url=' + documentUrl + '&embedded=true'
        })
    }

    render() {
        if (this.state.Url === '') {
            return (<div>
                <Loading loading={true} />
            </div>
            )
        }
        return (
            <Iframe src={this.state.Url} height="800" width="90%"></Iframe>
        );
    }
}
