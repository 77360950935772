import { createSlice } from "@reduxjs/toolkit";
import {RootState} from '../store'

interface LoginState {
    loginSuccess: boolean
}

const initState:LoginState = {
    loginSuccess: false
}

const loginSlice = createSlice({
    name: 'login',
    initialState: initState,
    reducers: {
        setLoginSuccess(state:LoginState) {
            state.loginSuccess = true
        },
        resetLoginSuccess(state:LoginState) {
            state.loginSuccess = false
        }
    }
})

export const { setLoginSuccess, resetLoginSuccess} = loginSlice.actions
export const selectLogin = (state:RootState) => {
    return state.loginReducer
}
export default loginSlice.reducer