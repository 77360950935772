import { DocumentDTO } from "../../../content-library/models/document";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { listDocuments, createDocument } from "../../actions/content-library/documentListActions";

export interface DocumentListState {
    isLoading: boolean,
    isCreatingDocument: boolean,
    documents: DocumentDTO[] | null,
    allDocuments: DocumentDTO[] | null,
    redirectTo: string,
    fetchError: any
}

const initState:DocumentListState = {
    isCreatingDocument: false,
    isLoading: false,
    documents: null,
    allDocuments: null,
    fetchError: null,
    redirectTo: ""
}

/**
 * Creates reducers and actions for documentList
 */

const documentListSlice = createSlice({
    name: 'documentList',
    initialState: initState,
    reducers: {
        search(state:DocumentListState, {payload}: PayloadAction<string>) {
            if (payload.length === 0) {
                state.documents = state.allDocuments
            } else {
                const filteredList = state.documents?.filter((doc) => {
                    if(doc.name.toLowerCase().indexOf(payload.toLowerCase()) >= 0) {
                        return doc
                    }
                })
                if (filteredList) {
                    state.documents = filteredList
                }
            }
        },
        resetRedirect(state:DocumentListState) {
            state.redirectTo = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listDocuments.pending, (state) => {
            state.isLoading = true;
            state.fetchError = null;
        });

        builder.addCase(listDocuments.fulfilled, (state, {payload}) => {
            state.documents = payload
            state.allDocuments = payload
            state.isLoading = false
        });

        builder.addCase(listDocuments.rejected, (state, {error}) => {
            state.isLoading = false;
            state.fetchError = error;
        });

        builder.addCase(createDocument.pending, (state) => {
            state.isCreatingDocument = true;
            state.fetchError = null;
        });

        builder.addCase(createDocument.fulfilled, (state, {payload}) => {
            state.isCreatingDocument = false;
            const {name} = payload;
            state.redirectTo = `./home/${name.toUpperCase().replace(new RegExp(' ', 'g'), '')}`;
            if (state.documents) {
                state.documents.push(payload);
            }
            
        });

        builder.addCase(createDocument.rejected, (state, {error}) => {
            state.isCreatingDocument = false;
            state.fetchError = error;
        });
    }
})

export const selectDocumentList = (state:RootState) => {
    return state.documentListReducer
}

export const { search, resetRedirect } = documentListSlice.actions

export default documentListSlice.reducer