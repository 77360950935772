import React, {useState, useEffect} from 'react'
import "./qaPairEdit.scss"
import { styled } from '@mui/material/styles';
import {
    Dialog, 
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material'
import Spinner from '../spinner/spinner';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'


const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        minWidth: '800px',
        maxHeight: '800px',
        minHeight: '400px'
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))

type QAPairEditProps = {
    question?: string,
    isUpdating: boolean,
    answer?: string,
    onSubmit: (question:string,answer:string) => void,
    onClose: () => void,
    open: boolean
}

/**
 * Returns a dialog component for editing the question & answer pair
 */
const QAPairEdit = ({question, answer, onSubmit, open, onClose, isUpdating}:QAPairEditProps) => {
    const [newQuestion,setNewQuestion] = useState<string>(question || "")
    const [newAnswer, setNewAnswer] = useState<string>(answer || "")

    useEffect(() => {
        return () => {
            // Reset state onClose
            if (!question && !answer) {
                setNewQuestion("")
                setNewAnswer("")
            }
        }
    }, [open])
    return(
        <StyledDialog open={open} onClose={onClose} sx={{minWidth: "600px"}}>
            <DialogContent>
                <DialogTitle>Edit question and answer</DialogTitle>
                <form>
                    <div className='input-container'>
                        <h2>Question</h2>
                        <input value={newQuestion} onChange={e => setNewQuestion(e.target.value)}/>
                    </div>
                    <div className='input-container' data-testid="response">
                        <h2>Response</h2>
                        <ReactQuill value={newAnswer} onChange={setNewAnswer}/>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <button className='edit-buttons cancel' onClick={onClose}>CANCEL</button>
                <button className='edit-buttons save' onClick={() => onSubmit(newQuestion, newAnswer)}>
                    {isUpdating ? <Spinner isButton={true}/> : 'SAVE'}
                    </button>
            </DialogActions>
        </StyledDialog>
    )
}

export default QAPairEdit
