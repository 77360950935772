import React from "react";
import * as _ from "lodash";
import Kendra from "aws-sdk/clients/kendra";

import { isNullOrUndefined } from "../../utils";
import "../../search.scss";

import { Relevance } from "../../constants";
import { SvgIcon, Tooltip } from "@mui/material";
import { FileCopyOutlined, InfoOutlined, LaunchOutlined } from "@mui/icons-material";

const IgnoreFormats = ["PLAIN_TEXT"];

interface ResultFooterProps {
  queryResultItem: Kendra.QueryResultItem;
  attributes: any;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;

  openResultDialog: (
    resultItem: Kendra.QueryResultItem
  ) => void;

  launchDocumentView: (
    resultItem: Kendra.QueryResultItem
  ) => void;

  showSnackbar: (
    content: string
  ) => void;
}

export default class ResultFooter extends React.Component<
  ResultFooterProps,
  {}
> {
  private submitClickFeedback = () => {
    this.props.submitFeedback(Relevance.Click, this.props.queryResultItem);
  };
  private openResultDialog = () => {
    this.props.openResultDialog(this.props.queryResultItem);
  };
  private launchDocumentView = () => {
    this.props.launchDocumentView(this.props.queryResultItem);
  };
  private showSnackbar = (content: string) => {
    this.props.showSnackbar(content);
  };

  render() {
    const { attributes, queryResultItem } = this.props;

    const fileFormatName = attributes.FileFormat
      ? attributes.FileFormat.StringValue
      : undefined;

    let fileFormat;
    if (
      !isNullOrUndefined(fileFormatName) &&
      IgnoreFormats.indexOf(fileFormatName) === -1
    ) {
      fileFormat = (
        <div className="display-inline">
          {fileFormatName.sourceLink()}
          <div className="file-format-divider-wrapper">
            <div className="file-format-divider" />
          </div>
        </div>
      );
    }

    const uri = queryResultItem.DocumentURI;

    const contentType = uri && uri.includes('docs.aws.amazon.com') ? "Source: Public Documentation" : "Source: Standard Content";

    return (
      <div className="result-footer">
        <div className="footer-left-text">
          {fileFormat}
          {contentType}
        </div>
        <div className="footer-right-text">
          <div className="actions">
            {this.props.queryResultItem.DocumentId !== undefined && this.props.queryResultItem.DocumentId?.indexOf('https') < 0 ?
              <Tooltip placement="left" title="More Info">
                <span className="clickableIcon" onClick={() => {
                  this.openResultDialog();
                }}><SvgIcon component={InfoOutlined}></SvgIcon></span>
              </Tooltip>
              : <span></span>
            }
            <br /><br />
            <Tooltip placement="left" title="View Full Response">
              <span className="clickableIcon" onClick={() => {
                this.launchDocumentView();
              }}><SvgIcon component={LaunchOutlined}></SvgIcon></span>
            </Tooltip>
            <br /><br />
            <Tooltip placement="left" title="Copy to Clipboard">
              <span className="clickableIcon" onClick={() => {
                this.showSnackbar('Copied');
                navigator.clipboard.writeText(this.props.queryResultItem.DocumentExcerpt!.Text!);
              }}><SvgIcon component={FileCopyOutlined}></SvgIcon></span>
            </Tooltip><br /><br /><br /><br />
          </div>
        </div>
      </div>
    );
  }
}
