import { combineReducers } from "redux";
import documentViewSlice from './slices/content-library/documentViewSlice'
import documentListSlice from "./slices/content-library/documentListSlice";
import loginSlice from "./slices/loginSlice";

const rootReducer = combineReducers({
    documentViewReducer: documentViewSlice,
    documentListReducer: documentListSlice,
    loginReducer: loginSlice
})


export default rootReducer