import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Loading(props) {
    if (props.loading) {
        return (
            <Stack spacing={1}>
                <Skeleton variant="text" />
                <Skeleton variant="rectangular" height="20vh" />
                <Skeleton variant="text" />
                <Skeleton variant="rectangular" height="20vh" />
            </Stack>
        )
    }
    else {
        return <span></span>
    }
}