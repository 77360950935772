import Kendra from "aws-sdk/clients/kendra";
import { CREDENTIALS_FILE_NAME, CREDENTIALS_FILE_PATH } from "./constants";
import S3 from "aws-sdk/clients/s3";

const _loadingErrors = [];

const { REACT_APP_REGION, REACT_APP_INDEX_ID } = process.env;

let config = {
  region: REACT_APP_REGION,
  indexId: REACT_APP_INDEX_ID
};


if (config) {
  if (!config.region) {
    _loadingErrors.push(
      `There is no region provided in ${CREDENTIALS_FILE_PATH}/${CREDENTIALS_FILE_NAME}`
    );
  }
  if (!config.indexId || config.indexId.length === 0) {
    _loadingErrors.push(
      `There is no indexId provided in ${CREDENTIALS_FILE_PATH}/${CREDENTIALS_FILE_NAME}`
    );
  }
}

const hasErrors = _loadingErrors.length > 0;
if (hasErrors) {
  console.error(JSON.stringify(_loadingErrors));
}

export const errors = _loadingErrors;

export const indexId = config ? config.indexId : undefined;

export const kendraClient = (keyId: string, secret: string, sessionToken: string) => {
  return !hasErrors ?
    new Kendra({
      accessKeyId: keyId,
      secretAccessKey: secret,
      region: config.region,
      sessionToken: sessionToken
    })
    : undefined;
}

export const s3Client = (keyId: string, secret: string, sessionToken: string) => {

  return !hasErrors ?
    new S3({
      accessKeyId: keyId,
      secretAccessKey: secret,
      region: config.region,
      sessionToken: sessionToken
    })
    : undefined;
}
