export default class DocumentMetadataResponse {
    constructor() {
        this.Id = '';
        this.Name = '';
        this.CreatedTimestamp = new Date();
        this.ModifiedTimestamp = new Date();
    }

    Id: string;
    Name: string;
    CreatedTimestamp: Date;
    ModifiedTimestamp: Date;

    static generateFriendlyDate(input: Date | undefined): string {
        if (input === undefined) {
            return '';
        }

        const parsedDate = new Date(input);

        return `${parsedDate.toLocaleDateString()} ${parsedDate.toLocaleTimeString()}`;
    }
}