import React from 'react';
import { DEFAULT_SORT_ATTRIBUTE } from '../../../search/sorting/constants';
import { DocumentDTO } from '../../models/document';
import "./searchResultsBar.scss"

type SearchResultsBarProps = {
    results: DocumentDTO[];
    handleSort: () => void;
}

/**
 * Component that renders total numbers of search results and that provides sorting.
 */
const SearchResultsBar = ({results, handleSort}:SearchResultsBarProps) => {
    return(
        <div className="search-results-bar-wrapper">
            <h3 className='search-results-bar-text'>
                Total: <b>{results.length}</b> results
            </h3>
            <div className="search-results-sort-container">
                <h3 className='search-results-bar-text'>
                    Sort by: 
                </h3>
                <select className="search-results-sort">
                    <option value={DEFAULT_SORT_ATTRIBUTE}>{DEFAULT_SORT_ATTRIBUTE}</option>
                </select>
            </div>
        </div>
    )
}

export default SearchResultsBar;