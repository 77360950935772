import { createTheme } from "@mui/material";
import AmazonEmberWoff2 from './assets/fonts/AmazonEmber_W_Rg.woff2'

export const theme = createTheme({
    typography: {
      fontFamily: 'AmazonEmber',
      h1: {
        fontSize: '4.4rem',
        fontWeight: '300',
        lineHeight: '5rem'
      },
      h2: {
        fontSize: '1.5rem',
        lineHeight: '2rem'
      },
      h3: {
        fontSize: '1.2rem',
        lineHeight: '2rem',
        fontWeight: '700'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'AmazonEmber';
            src: local('AmazonEmber'), local('AmazonEmber-Regular'), url(${AmazonEmberWoff2}) format('woff2');
          }
        `,
      },
    },
    palette: {
      primary: {
        light: '#E5E5E5',
        main: '#232f3e',
        dark: '#000000'
      },
      secondary: {
        main: '#df8244',
      },
    },
  });