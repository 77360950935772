import React from "react";
import * as _ from "lodash";
import Kendra from "aws-sdk/clients/kendra";

import TextHighlights from "./TextWithHighlight";
import "../../search.scss";
import { Relevance } from "../../constants";
import { Typography } from "@mui/material";

interface ResultTitleProps {
  queryResultItem: Kendra.QueryResultItem;
  attributes: any;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;

  launchDocumentView: (
    resultItem: Kendra.QueryResultItem
  ) => void;
}

export default class ResultTitle extends React.Component<ResultTitleProps, {}> {
  private submitClickFeedback = () => {
    this.props.submitFeedback(Relevance.Click, this.props.queryResultItem);
    this.props.launchDocumentView(this.props.queryResultItem);
  };

  render() {
    const { queryResultItem } = this.props;

    // title is not guaranteed to exist, show nothing if that's the case
    let resultTitle: React.ReactNode = null;
    if (
      queryResultItem.DocumentTitle &&
      !_.isEmpty(queryResultItem.DocumentTitle.Text)
    ) {
      resultTitle = (
        <TextHighlights textWithHighlights={queryResultItem.DocumentTitle} />
      );
    } else if (queryResultItem.DocumentURI) {
      resultTitle = queryResultItem.DocumentURI;
    } else {
      return null;
    }

    let uri = queryResultItem.DocumentURI;

    if (queryResultItem.Type === 'DOCUMENT') {
      uri = './view-document/' + queryResultItem.DocumentId;
    }

    if (uri && !_.isEmpty(uri)) {
      resultTitle = (
        <a
          className="action-link"
          onClick={this.submitClickFeedback}
        >
          {resultTitle}
        </a>
      );
    }

    return <Typography className="clickableIcon" component="h2" variant="h2" align="left" color="textPrimary">
      {resultTitle}
    </Typography>
  }
}
