import React from 'react'

type CheckmarkIconProps = {
    fill?: string,
    height?: number,
    width?: number
}

const CheckmarkIcon = ({fill="white", height=22, width=22}:CheckmarkIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={width} height={height} preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
            <path fill={fill} d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415l-9 8.999z"/>
            <path fill={fill} d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z"/>
        </svg>
    )
}

export default CheckmarkIcon