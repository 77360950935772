import React, { useEffect, useState } from "react";
import './App.css';
import ROUTES, { RenderRoutes } from './routes/router'
import Navigator from "./components/topNavigator";
import BottomNav from "./components/bottomNavigator";
import Amplify, { Auth, Hub } from "aws-amplify";
import '@aws-amplify/ui/dist/style.css';
import FederatedSignIn from "./views/login/login";
import ConfigureAuthentication from "./AuthenticationConfig";
import jwt_decode from "jwt-decode";
import {setLoginSuccess} from '../src/store/slices/loginSlice'
import { useAppDispatch } from '../src/store/hooks'

Amplify.configure(ConfigureAuthentication());

function App() {

  const dispatch = useAppDispatch()

  const storedToken = String(localStorage.getItem("token"))

  const [token, setToken] = useState(
    storedToken != 'null' ? storedToken : null
  );

  useEffect(() => {
    localStorage.setItem("token", String(token))

    if (token && token !== 'null' && token !== null) {
      const timeNow = Math.floor(new Date() / 1000)
      const expired = Number(jwt_decode(token).exp) <= timeNow
      if (expired) {
        localStorage.setItem("token", String(null))
        setToken(null)
      }
    }
  }, [token])

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn": dispatch(setLoginSuccess()) 
        case "cognitoHostedUI":
          Auth.currentSession()
            .then(session => { setToken(session.getAccessToken().jwtToken); } )
            .catch(err => console.log(err));
          break;
        case "signOut":
          setToken(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <div className="App">
      <Navigator />
      {token ? (
        <>
          <RenderRoutes routes={ROUTES} />
        </>
      ) : (
        <FederatedSignIn federatedIdName={"MidwayAuth"} />
      )}
      <div>
      </div>
      <BottomNav />
    </div>
  )
}

export default App;