import { DocumentDTO, DefaultDocument } from '../../../content-library/models/document'
import {createSlice} from "@reduxjs/toolkit"
import {RootState} from '../../store'
import {
    loadDocument, 
    addSection,
    updateSection,
    addQuestion,
    removeQuestion,
    updateAnswer,
    updateQuestion,
    loadSection, 
    updateStatus, 
    updateOwners,
    updateTags,
    updateDocumentName
} from '../../actions/content-library/documentViewActions'

export interface DocumentState {
    isLoading: boolean,
    isUpdating: boolean,
    document: DocumentDTO,
    fetchError: any
}

const initState:DocumentState = {
    isLoading: false,
    isUpdating: false,
    document: DefaultDocument,
    fetchError: null
}

/**
 * Creates reducers and actions for documentView
 */
const documentViewSlice = createSlice({
    name: 'documentView',
    initialState:initState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadDocument.pending, (state) => {
            state.isLoading = true;
            state.fetchError = null;
        });

        builder.addCase(loadDocument.fulfilled, (state, {payload}) => {
            state.document = payload;
            state.isLoading = false;
        });

        builder.addCase(loadDocument.rejected, (state, {error}) => {
            state.isLoading = false;
            state.fetchError = error;
        });

        builder.addCase(loadSection.pending, (state) => {
            state.fetchError = null;
        });

        builder.addCase(loadSection.fulfilled, (state, {payload}) => {
            state.document = payload;
        });

        builder.addCase(loadSection.rejected, (state, {error}) => {
            state.fetchError = error;
        });

        builder.addCase(updateAnswer.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(updateAnswer.fulfilled, (state, {payload}) => {
            state.document = payload;
            state.isUpdating = false;
        }); 

        builder.addCase(updateAnswer.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });

        builder.addCase(updateQuestion.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(updateQuestion.fulfilled, (state, {payload}) => {
            state.document = payload;
            state.isUpdating = false;
        }); 

        builder.addCase(updateQuestion.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });
        

        builder.addCase(addSection.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(addSection.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document = payload;
        });

        builder.addCase(addSection.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });

        builder.addCase(updateSection.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(updateSection.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document = payload;
        });

        builder.addCase(updateSection.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });

        builder.addCase(addQuestion.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(addQuestion.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document = payload;
        });

        builder.addCase(addQuestion.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error
        });

        builder.addCase(removeQuestion.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(removeQuestion.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document = payload;
        });

        builder.addCase(removeQuestion.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });

        builder.addCase(updateStatus.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(updateStatus.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document.status = payload;  
        });

        builder.addCase(updateStatus.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });

        builder.addCase(updateOwners.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(updateOwners.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document.ownerOne = payload.ownerOne;
            state.document.ownerTwo = payload.ownerTwo;
        });

        builder.addCase(updateOwners.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });

        builder.addCase(updateTags.pending, (state) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(updateTags.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document.segment = payload.segment;
            state.document.industry = payload.industry;
        });

        builder.addCase(updateTags.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });

        builder.addCase(updateDocumentName.pending, (state, {payload}) => {
            state.isUpdating = true;
            state.fetchError = null;
        });

        builder.addCase(updateDocumentName.fulfilled, (state, {payload}) => {
            state.isUpdating = false;
            state.document.name = payload.name;
        });

        builder.addCase(updateDocumentName.rejected, (state, {error}) => {
            state.isUpdating = false;
            state.fetchError = error;
        });
    }
})


export const selectDocumentView = (state:RootState) => {
    return state.documentViewReducer
}

export default documentViewSlice.reducer


