import React from 'react'

type AlertIconProps = {
    fill?: string,
    height?: number,
    width?: number
}

const AlertIcon = ({fill="red", height=22, width=22}:AlertIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={width} height={height} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
            <g fill="none" stroke={fill} strokeLinecap="round" strokeWidth="2">
                <path d="M12 9v5m0 3.5v.5"/>
                <path strokeLinejoin="round" d="M2.232 19.016L10.35 3.052c.713-1.403 2.59-1.403 3.302 0l8.117 15.964C22.45 20.36 21.544 22 20.116 22H3.883c-1.427 0-2.334-1.64-1.65-2.984Z"/>
            </g>
        </svg>
    )
}

export default AlertIcon
