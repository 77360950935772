import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { BottomNavigation, BottomNavigationAction, Link, Paper, Typography } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupsIcon from '@mui/icons-material/Groups';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Amazon Web Services
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function FixedBottomNavigation() {
  return (
    <React.Fragment>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0  }}>
        <BottomNavigation
          showLabels
          onChange={(event, newValue) => {
            switch (newValue) {
              case 0:
                window.open('https://survey.fieldsense.whs.amazon.dev/survey/72feccc7-02e7-46c3-b163-c7bf2f7fb216/', '_blank');
                break;
              case 1:
                window.open('https://amzn-aws.slack.com/archives/C034UKKL14H/', '_blank');
                break;
              default: 
                window.open('https://survey.fieldsense.whs.amazon.dev/survey/72feccc7-02e7-46c3-b163-c7bf2f7fb216/', '_blank');
                break;
            }
          }}
        >
          <BottomNavigationAction label="Feedback" icon={<QuestionAnswerIcon />} />
          <BottomNavigationAction label="Contact Us" icon={<GroupsIcon />} />
          <BottomNavigationAction label={Copyright()} data-testid="copyright"/>
        </BottomNavigation>
      </Paper>
    </React.Fragment>
  );
}