import React, {useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import {
    Dialog, 
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField
} from '@mui/material'
import Spinner from '../spinner/spinner';
import "./editDocumentOwners.scss"


const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        width: "auto"
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))

type QAPairEditProps = {
    isUpdating: boolean,
    ownerOne: string,
    ownerTwo: string,
    documentName: string,
    onSubmit: (ownerOne:string,ownerTwo:string) => void,
    onClose: () => void,
    open: boolean
}

/**
 * Returns a dialog component for editing the question & answer pair
 */
const EditDocumentOwners = ({ownerOne, ownerTwo, documentName, onSubmit, open, onClose, isUpdating}:QAPairEditProps) => {
    const [newOwnerOne,setNewOwnerOne] = useState<string>(ownerOne || "")
    const [newOwnerTwo, setNewOwnerTwo] = useState<string>(ownerTwo || "")

    useEffect(() => {
        return () => {
            // Reset state onCloseçç
            setNewOwnerOne(ownerOne)
            setNewOwnerTwo(ownerTwo)
        }
    }, [open])

    return(
        <StyledDialog open={open} onClose={onClose} sx={{minWidth: "600px"}}>
            <DialogContent>
                <DialogTitle>{`Assign content owners to ${documentName}`}</DialogTitle>
                <div className='content-owners-container'>
                    <TextField 
                        id="outlined-basic" 
                        label="Owner 1" 
                        variant="outlined" 
                        value={newOwnerOne} 
                        onChange={(newValue) => setNewOwnerOne(newValue.target.value)} 
                        sx={{marginBottom: "20px"}}
                    />       
                    <TextField 
                        id="outlined-basic" 
                        label="Owner 2" 
                        variant="outlined" 
                        value={newOwnerTwo} 
                        onChange={(newValue) => setNewOwnerTwo(newValue.target.value)} />
                </div>
            </DialogContent>
            <DialogActions>
                <button className='edit-buttons cancel' onClick={onClose}>CANCEL</button>
                <button className='edit-buttons save' onClick={() => onSubmit(newOwnerOne, newOwnerTwo)}>
                    {isUpdating ? <Spinner isButton={true}/> : 'SAVE'}
                    </button>
            </DialogActions>
        </StyledDialog>
    )
}

export default EditDocumentOwners
