import axios, { AxiosRequestHeaders } from "axios";
import ContentLibraryAnswer from '../types/contentLibraryAnswer';
import { Auth } from 'aws-amplify';
import DocumentUrlResponse from "../types/documentUrlResponse";
import AvailableSourceResponse from "../types/availableSourceResponse";
import DocumentMetadataResponse from "../types/documentMetadata";

class ContentLibraryApi {
    private http = axios.create({
        baseURL: process.env.REACT_APP_API_URL ?? "https://891kgsj2fh.execute-api.us-east-1.amazonaws.com/MainStage/"
    });

    async search(questionContents: string, filters: string[]): Promise<ContentLibraryAnswer[]> {
        try {
            if (process.env.REACT_APP_DEBUG_MODE === 'true') {
                return [{
                    answerId: '1',
                    content: "Amazon Web Services is Media Broadcast's reliable partner for the modernization and extension of your OTT analytics environment in support of your OTT Playout Service for freenet TV and other channels. The combination of AWS cloud native services and the support through our AWS Professional Services experts guarantees a reliable, scalable and high-quality service that can easily be expanded for more live channels",
                    fullContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc vel risus commodo viverra maecenas accumsan lacus vel facilisis. A arcu cursus vitae congue mauris rhoncus aenean. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Mi proin sed libero enim sed faucibus turpis in eu. Fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque. Elit ut aliquam purus sit amet luctus venenatis lectus. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Mattis vulputate enim nulla aliquet. Vulputate odio ut enim blandit volutpat maecenas. Dui ut ornare lectus sit amet est placerat. Quis enim lobortis scelerisque fermentum. Volutpat commodo sed egestas egestas fringilla phasellus. Nunc congue nisi vitae suscipit tellus mauris a diam maecenas. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim.',
                    lastUpdated: new Date(),
                    owner: 'jamesuk',
                    source: 'Content Library',
                    title: 'Broadcast Media'
                },
                {
                    answerId: '2',
                    content: "Amazon Web Services is Media Broadcast's reliable partner for the modernization and extension of your OTT analytics environment in support of your OTT Playout Service for freenet TV and other channels. The combination of AWS cloud native services and the support through our AWS Professional Services experts guarantees a reliable, scalable and high-quality service that can easily be expanded for more live channels",
                    fullContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc vel risus commodo viverra maecenas accumsan lacus vel facilisis. A arcu cursus vitae congue mauris rhoncus aenean. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Mi proin sed libero enim sed faucibus turpis in eu. Fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque. Elit ut aliquam purus sit amet luctus venenatis lectus. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Mattis vulputate enim nulla aliquet. Vulputate odio ut enim blandit volutpat maecenas. Dui ut ornare lectus sit amet est placerat. Quis enim lobortis scelerisque fermentum. Volutpat commodo sed egestas egestas fringilla phasellus. Nunc congue nisi vitae suscipit tellus mauris a diam maecenas. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim.',
                    lastUpdated: new Date(),
                    owner: 'jamesuk',
                    source: 'Content Library',
                    title: 'Security'
                },
                {
                    answerId: '3',
                    content: "Amazon Web Services is Media Broadcast's reliable partner for the modernization and extension of your OTT analytics environment in support of your OTT Playout Service for freenet TV and other channels. The combination of AWS cloud native services and the support through our AWS Professional Services experts guarantees a reliable, scalable and high-quality service that can easily be expanded for more live channels",
                    fullContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc vel risus commodo viverra maecenas accumsan lacus vel facilisis. A arcu cursus vitae congue mauris rhoncus aenean. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Mi proin sed libero enim sed faucibus turpis in eu. Fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque. Elit ut aliquam purus sit amet luctus venenatis lectus. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Mattis vulputate enim nulla aliquet. Vulputate odio ut enim blandit volutpat maecenas. Dui ut ornare lectus sit amet est placerat. Quis enim lobortis scelerisque fermentum. Volutpat commodo sed egestas egestas fringilla phasellus. Nunc congue nisi vitae suscipit tellus mauris a diam maecenas. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim.',
                    lastUpdated: new Date(),
                    owner: 'jamesuk',
                    source: 'Content Library',
                    title: 'Energy Vertical'
                },
                {
                    answerId: '4',
                    content: "Amazon Web Services is Media Broadcast's reliable partner for the modernization and extension of your OTT analytics environment in support of your OTT Playout Service for freenet TV and other channels. The combination of AWS cloud native services and the support through our AWS Professional Services experts guarantees a reliable, scalable and high-quality service that can easily be expanded for more live channels",
                    fullContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc vel risus commodo viverra maecenas accumsan lacus vel facilisis. A arcu cursus vitae congue mauris rhoncus aenean. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Mi proin sed libero enim sed faucibus turpis in eu. Fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque. Elit ut aliquam purus sit amet luctus venenatis lectus. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Mattis vulputate enim nulla aliquet. Vulputate odio ut enim blandit volutpat maecenas. Dui ut ornare lectus sit amet est placerat. Quis enim lobortis scelerisque fermentum. Volutpat commodo sed egestas egestas fringilla phasellus. Nunc congue nisi vitae suscipit tellus mauris a diam maecenas. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim.',
                    lastUpdated: new Date(),
                    owner: 'jamesuk',
                    source: 'Content Library',
                    title: 'Another document'
                }];
            }
            else {
                let headers = await this.setDefaultHeaders();

                const apiResponse = await this.http.post<ContentLibraryAnswer[]>("search", {
                    question: questionContents,
                    filters: filters
                }, {
                    headers: headers
                });

                if (apiResponse.data === null) {
                    return [];
                }

                return apiResponse.data;
            }
        }
        catch (err) {
            console.log(err)

            if (err === 'No current user') {
                await Auth.federatedSignIn();
            }

            return [];
        }
    }

    async listSources(): Promise<AvailableSourceResponse[]> {
        let headers = await this.setDefaultHeaders();

        const apiResponse = await this.http.get<AvailableSourceResponse[]>("available-sources", {
            headers: headers
        });

        if (apiResponse.data === null) {
            return []
        }

        return apiResponse.data;
    }

    async getDocumentUrl(documentId: string): Promise<string> {
        let headers = await this.setDefaultHeaders();

        const apiResponse = await this.http.post<DocumentUrlResponse>("load-document", {
            DocumentId: documentId
        }, {
            headers: headers
        });

        if (apiResponse.data === null) {
            return '';
        }

        return apiResponse.data.documentUrl;
    }

    async getDocumentMetadata(documentId: string): Promise<DocumentMetadataResponse> {
        let headers = await this.setDefaultHeaders();

        const apiResponse = await this.http.post<DocumentMetadataResponse>("metadata", {
            DocumentId: documentId
        }, {
            headers: headers
        });

        if (apiResponse.data === null) {
            return {
                Id: '',
                Name: '',
                CreatedTimestamp: new Date(),
                ModifiedTimestamp: new Date()
            };
        }

        return apiResponse.data;
    }

    async setDefaultHeaders(): Promise<AxiosRequestHeaders> {
        const session = await Auth.currentSession();

        const headers: AxiosRequestHeaders = {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`
        };

        return headers;
    }
}

export default new ContentLibraryApi();