import React from 'react';
import "./spinner.scss"

type SpinnerProps = {
    isButton?:boolean
}

/**
 * Simple spinning wheel to use in asynchronous calls.
 */
export default function Spinner({isButton}:SpinnerProps){
    return(
        <div className={`spinner-container ${isButton && 'btn-margin'}`}>
            <div className={`loading-spinner ${isButton && 'spinner-button'}`}></div>
        </div>
    )
}
