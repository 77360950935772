import React from 'react'

type TagIconProps = {
    width?: number
    height?: number
    fill?: string
}

/**
 * Simple Tag SVG Icon 
 */
const TagIcon = ({width = 12, height = 12, fill = "#838383"}:TagIconProps) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8242 5.27266L11.4195 0.998828C11.399 0.777344 11.2227 0.602344 11.0012 0.580469L6.72734 0.175781H6.72187C6.67812 0.175781 6.64395 0.189453 6.61797 0.21543L0.21543 6.61797C0.202755 6.63062 0.1927 6.64564 0.185839 6.66218C0.178978 6.67872 0.175447 6.69645 0.175447 6.71436C0.175447 6.73226 0.178978 6.74999 0.185839 6.76653C0.1927 6.78307 0.202755 6.79809 0.21543 6.81074L5.18926 11.7846C5.21523 11.8105 5.24941 11.8242 5.28633 11.8242C5.32324 11.8242 5.35742 11.8105 5.3834 11.7846L11.7859 5.38203C11.8133 5.35332 11.827 5.31367 11.8242 5.27266ZM5.28496 10.4119L1.58809 6.71504L7.05273 1.25039L10.4297 1.57031L10.7496 4.94727L5.28496 10.4119ZM8.29687 2.5C7.63379 2.5 7.09375 3.04004 7.09375 3.70312C7.09375 4.36621 7.63379 4.90625 8.29687 4.90625C8.95996 4.90625 9.5 4.36621 9.5 3.70312C9.5 3.04004 8.95996 2.5 8.29687 2.5ZM8.29687 4.14062C8.05488 4.14062 7.85937 3.94512 7.85937 3.70312C7.85937 3.46113 8.05488 3.26562 8.29687 3.26562C8.53887 3.26562 8.73437 3.46113 8.73437 3.70312C8.73437 3.94512 8.53887 4.14062 8.29687 4.14062Z"/>
        </svg>
    )
}

export default TagIcon;