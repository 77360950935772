import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
    Dialog, 
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material'
import Spinner from '../spinner/spinner'
import "../qaPair/qaPairEdit.scss"

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        minWidth: '400px',
        maxHeight: '300px',
        minHeight: '200px'
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))

type EditTitleProps = {
    title: string,
    label?: string,
    inputLabel?: string,
    onSubmit: (name:string) => void,
    onClose: () => void,
    open: boolean,
    isUpdating: boolean,
}

const EditTitle = ({title,label, inputLabel, onSubmit,onClose,open, isUpdating}:EditTitleProps) => {
    const [newTitle, setNewTitle] = useState<string>(title)

    useEffect(() => {
        return () => {
            // Reset state onClose
            setNewTitle(title)
        }
    }, [open])

    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogContent>
                {label && (
                    <DialogTitle>{label}</DialogTitle>
                )}
                <div className='input-container'>
                    <h2>{inputLabel || "Title"}</h2>
                    <input value={newTitle} onChange={e => setNewTitle(e.target.value)}/>
                </div>
            </DialogContent>
            <DialogActions>
                <button className='edit-buttons cancel' onClick={onClose}>CANCEL</button>
                <button className='edit-buttons save' onClick={() => onSubmit(newTitle)}>
                    {isUpdating ? <Spinner isButton={true}/> : 'SAVE'}
                    </button>
            </DialogActions>
        </StyledDialog>
    )
}

export default EditTitle