import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { DocumentDTO } from "../../../content-library/models/document";

import contentLibraryAdminApi from "../../../content-library/services/contentLibraryAdminApi";
import { UpdateMetadaProps } from "../../../content-library/models/update-metadata";
import { UpdateQuestionProps } from "../../../content-library/models/update-question";


type LoadDocumentData = {
    name: string,
    questionIdentifier: string
}
/**
 * Async action that fetches document from content library and returns the document as payload to the
 * reducer.
 */
export const loadDocument = createAsyncThunk(
    "document/load",
    async(loadDocumentData:LoadDocumentData, { rejectWithValue }) => {
        const {name, questionIdentifier} = loadDocumentData
        try {
            const document = await contentLibraryAdminApi.getDocument(name)
            if (!questionIdentifier) {
                return document
            } else {
                const question = await contentLibraryAdminApi.getQuestion(questionIdentifier)
                const newSection = await contentLibraryAdminApi.getSection(document.documentIdentifier, question.sectionIdentifier);
                let newSections = document.sections.map((section) => {
                    if (section.sectionIdentifier === newSection.sectionIdentifier) {
                        return {
                            ...newSection
                        }
                    } else {
                        return section
                    }
                });

                var newDocument:DocumentDTO = {
                    ...document,
                    sections: newSections
                }
                return newDocument
            }
            
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)

type SectionData = {
    section: string,
    documentIdentifier: string,
}

export const addSection = createAsyncThunk(
    "document/addSection",
    async(sectionData:SectionData, { rejectWithValue, getState }) => {
        const {section, documentIdentifier} = sectionData;
        const state = getState() as RootState
        const {sections} = state.documentViewReducer.document
        try {
            const previousSections = sections
            const document = await contentLibraryAdminApi.addDocumentSection(documentIdentifier, section, sections.length);
            let newSections = document.sections.map((s) => {
                const prevSection = previousSections.find(ps => ps.sectionIdentifier === s.sectionIdentifier)
                if (prevSection) {
                    return prevSection
                } else {
                    return s
                }
            })
            var newDocument:DocumentDTO = {
                ...document,
                sections: newSections
            }

            return newDocument
        } catch(err) {
            console.log(err)
            return rejectWithValue(err)
        }
    }
)

type SectionMetadata = {
    documentIdentifier: string,
    sectionIdentifier: string,
    name: string
}

export const updateSection = createAsyncThunk(
    "document/updateSection",
    async(metaData: SectionMetadata, {rejectWithValue, getState}) => {
        const state = getState() as RootState
        const {documentIdentifier, sectionIdentifier, name} = metaData;
        try {
            const newSection = await contentLibraryAdminApi.updateDocumentSection(documentIdentifier, sectionIdentifier, name);
            let document = state.documentViewReducer.document;
            let newSections = document.sections.map((section) => {
                if (section.sectionIdentifier === sectionIdentifier) {
                    return {
                        ...section,
                        name: newSection.name
                    }
                } else {
                    return section
                }
            });

            var newDocument:DocumentDTO = {
                ...document,
                sections: newSections
            }
            
            return newDocument;
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)



type LoadSectionData = {
    documentIdentifier: string,
    sectionIdentifier: string
}

export const loadSection = createAsyncThunk(
    "document/loadSection",
    async(loadSectionData:LoadSectionData, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        const {documentIdentifier, sectionIdentifier} = loadSectionData;
        try {
            const newSection = await contentLibraryAdminApi.getSection(documentIdentifier, sectionIdentifier)
            let document = state.documentViewReducer.document
            let newSections = document.sections.map((section) => {
                if (section.sectionIdentifier === sectionIdentifier) {
                    return  {
                        ...newSection
                    }
                } else {
                    return section
                }
            })
            var newDocument:DocumentDTO = {
                ...document,
                sections: newSections
            }
            return newDocument
        } catch(err) {
            console.log(err)
            return rejectWithValue(err)
        }
    }
)

type AnswerData = {
    documentId: string,
    questionIdentifier: string,
    answer: string,
    sectionIdentifier: string
}

/**
 * Async action that updates answer and returns the document with the updates answer as payload to the reducer.
 */
export const updateAnswer = createAsyncThunk(
    "document/updateAnswer",
    async(answerData:AnswerData, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        const {documentId, questionIdentifier, answer, sectionIdentifier} = answerData
        try {
            await contentLibraryAdminApi.updateAnswer(documentId, questionIdentifier, answer, sectionIdentifier)
            let document = state.documentViewReducer.document
            let newSections = document.sections.map((section) => {
                if (section.sectionIdentifier === sectionIdentifier) {
                    let newQuestions = section.questions.map((question) => {
                        if (question.questionIdentifier === questionIdentifier){
                            return {
                                questionIdentifier: questionIdentifier,
                                question: question.question,
                                answer: answer,
                                sectionIdentifier: sectionIdentifier,
                                createdAt: question.createdAt,
                                createdBy: question.createdBy,
                                updatedBy: question.updatedBy,
                                lastUpdated: question.lastUpdated,
                                assignee: question.assignee
                            }
                        } else {
                            return question
                        }
                    })
                    return {
                        ...section,
                        questions: newQuestions
                    }
                } else {
                    return section
                }
            })
  
            // Create new document with updated questions
            var newDocument:DocumentDTO = {
                ...document,
                sections: newSections
            }
            return newDocument
            
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)

export const updateQuestion = createAsyncThunk(
    "document/updateQuestion",
    async(questionData:UpdateQuestionProps, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        const {
            questionIdentifier, 
            sectionIdentifier,
        } = questionData
        try {
            const newQuestion = await contentLibraryAdminApi.updateQuestion(questionData);
            let document = state.documentViewReducer.document
            let newSections = document.sections.map((section) => {
                if (section.sectionIdentifier === sectionIdentifier) {
                    let newQuestions = section.questions.map((question) => {
                        if (question.questionIdentifier === questionIdentifier){
                            return {
                                ...newQuestion,
                                answer: questionData.answer || question.answer
                            }
                        } else {
                            return question
                        }
                    })
                    return {
                        ...section,
                        questions: newQuestions
                    }
                } else {
                    return section
                }
            })
  
            // Create new document with updated questions
            var newDocument:DocumentDTO = {
                ...document,
                sections: newSections
            }
            return newDocument
            
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)

type QuestionData = {
    documentId: string,
    question: string,
    answer: string,
    sectionIdentifier: string
}

/**
 * Async action that adds a new question to the document and returns the modified document as payload to the reducer.
 */
export const addQuestion = createAsyncThunk(
    "document/addQuestion",
    async(questionData:QuestionData, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        const {documentId, question, answer, sectionIdentifier} = questionData
        try {
            const newQuestion = await contentLibraryAdminApi.addDocumentQuestion(documentId, question, answer, sectionIdentifier)
            const document = state.documentViewReducer.document
            let newSections = document.sections.map((section) => {
                if (section.sectionIdentifier === sectionIdentifier) {
                    return {
                        ...section,
                        questions: [...section.questions, newQuestion]
                    }
                } else {
                    return section
                }
            })

            var newDocument:DocumentDTO = {
                ...document,
                sections: newSections
            }

            return newDocument;

        } catch(err) {
            console.log(err)
            return rejectWithValue(err)
        }
    }
)


export const getSectionByQuestion = createAsyncThunk(
    "document/getQuestion",
    async(questionIdentifier: string, { rejectWithValue }) => {

    }
)

type RemoveQuestionData = {
    documentIdentifier: string,
    questionIdentifier: string
    sectionIdentifier: string
}

export const removeQuestion = createAsyncThunk(
    "document/removeQuestion",
    async(removeQuestionData:RemoveQuestionData, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        const {documentIdentifier, questionIdentifier, sectionIdentifier} = removeQuestionData
        try {
            await contentLibraryAdminApi.removeDocumentQuestion(documentIdentifier, questionIdentifier)
            let document = state.documentViewReducer.document
            let newSections = document.sections.map((section) => {
                if (section.sectionIdentifier === sectionIdentifier) {
                    let newQuestions = section.questions.filter((q) => q.questionIdentifier !== questionIdentifier)
                    return {
                        ...section,
                        questions: newQuestions
                    }
                } else {
                    return section
                }
            })
  
            // Create new document with updated questions
            var newDocument:DocumentDTO = {
                ...document,
                sections: newSections
            }
            return newDocument
            
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)



type StatusData = {
    documentIdentifier: string,
    status: string
}

export const updateStatus = createAsyncThunk(
    "document/updateStatus",
    async(statusData:StatusData, { rejectWithValue }) => {
        const {documentIdentifier, status} = statusData
        try {
            await contentLibraryAdminApi.updateStatus(documentIdentifier, status)
            return status
        } catch(err) {
            return rejectWithValue(err)
        }
    } 
)

export const updateOwners = createAsyncThunk(
    "document/updateContentOwners",
    async(metaData: UpdateMetadaProps, { rejectWithValue }) => {
        const {ownerOne, ownerTwo} = metaData
        try {
            await contentLibraryAdminApi.updateMetadata(metaData)
            return {
                ownerOne: ownerOne,
                ownerTwo: ownerTwo
            }
        } catch(err) {
            return rejectWithValue(err)
        } 
    }
)

export const updateTags = createAsyncThunk(
    "document/updateTags",
    async(metaData:UpdateMetadaProps, { rejectWithValue }) => {
        const {segment, industry} = metaData
        try {
            await contentLibraryAdminApi.updateMetadata(metaData)
            return {
                segment: segment,
                industry: industry
            }
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)

export const updateDocumentName = createAsyncThunk(
    "document/updateDocumentName",
    async(metaData:UpdateMetadaProps, { rejectWithValue }) => {
        const {name} = metaData
        try {
            await contentLibraryAdminApi.updateMetadata(metaData)
            return {
                name: name
            }
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)

