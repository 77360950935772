function ConfigureAuthentication() {
    const REACT_MIDWAY_DOMAIN = process.env.REACT_APP_MIDWAY_DOMAIN
    const REACT_MIDWAY_REDIRECT_URL = process.env.REACT_APP_MIDWAY_REDIRECT_URL
    const REACT_REGION = process.env.REACT_APP_REGION
    const REACT_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID
    const REACT_APP_USER_IDENTITY_POOL_ID = process.env.REACT_APP_USER_IDENTITY_POOL_ID
    const REACT_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID

    const oauth = {
        domain: REACT_MIDWAY_DOMAIN,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: REACT_MIDWAY_REDIRECT_URL,
        redirectSignOut: REACT_MIDWAY_REDIRECT_URL,
        responseType: 'code',
        options: {
            AdvancedSecurityDataCollectionFlag: false
        }
    };

    const config = {
        Auth: {
            region: REACT_REGION,
            userPoolId: REACT_USER_POOL_ID,
            userPoolWebClientId: REACT_USER_POOL_WEB_CLIENT_ID,
            identityPoolId: REACT_APP_USER_IDENTITY_POOL_ID,
            oauth: oauth,
        }
    };
    return config;
}

export default ConfigureAuthentication;