import React, {useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import {
    Dialog, 
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Button,
    SvgIcon,
} from '@mui/material'
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import Spinner from '../spinner/spinner';
import "./editDocumentMetaData.scss"


const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        width: "auto"
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))

type QAPairEditProps = {
    isUpdating: boolean,
    industry: string,
    segment: string,
    onSubmit: (segment:string,industry:string) => void,
    onClose: () => void,
    open: boolean
}

/**
 * Returns a dialog component for editing the question & answer pair
 */
const EditDocumentMetaData = ({industry, segment, onSubmit, open, onClose, isUpdating}:QAPairEditProps) => {
    const [newIndustry,setNewIndustry] = useState<string>(industry || "")
    const [newSegment, setNewSegement] = useState<string>(segment || "")

    useEffect(() => {
        return () => {
            // Reset state onCloseçç
            setNewIndustry(industry)
            setNewSegement(segment)
        }
    }, [open])

    return(
        <StyledDialog open={open} onClose={onClose} sx={{minWidth: "600px"}}>
            <DialogContent>
                <DialogTitle>Assign Tags</DialogTitle>
                <div className='meta-data-container'>
                    <h3>Industry</h3>
                    <div className='tag-container'>
                        <Button color="primary" className={newIndustry === 'Automotive' ? 'filled' : ''} onClick={() => setNewIndustry('Automotive')}><SvgIcon component={BookmarksIcon}></SvgIcon>Automotive</Button>
                        <Button color="primary" className={newIndustry === 'Energy & Utilities' ? 'filled' : ''} onClick={() => setNewIndustry("Energy & Utilities")}><SvgIcon component={BookmarksIcon}></SvgIcon>Energy &amp; Utilities</Button>
                        <Button color="primary" className={newIndustry === 'Financial Services' ? 'filled' : ''} onClick={() => setNewIndustry('Financial Services')}><SvgIcon component={BookmarksIcon}></SvgIcon>Financial Services</Button>
                        <Button color="primary" className={newIndustry === 'Media & Entertainment' ? 'filled' : ''} onClick={() => setNewIndustry('Media & Entertainment')}><SvgIcon component={BookmarksIcon}></SvgIcon>Media &amp; Entertainment</Button>
                        <Button color="primary" className={newIndustry === 'Manufacturing' ? 'filled' : ''} onClick={() => setNewIndustry('Manufacturing')}><SvgIcon component={BookmarksIcon}></SvgIcon>Manufacturing</Button>
                        <Button color="primary" className={newIndustry === 'Retail' ? 'filled' : ''} onClick={() => setNewIndustry('Retail')}><SvgIcon component={BookmarksIcon}></SvgIcon>Retail</Button>
                    </div>
                </div>
                <div className='meta-data-container'>
                    <h3>Segment</h3>
                    <div className='tag-container'>
                        <Button color="primary" className={newSegment === 'Commercial' ? 'filled' : ''} onClick={() => setNewSegement('Commercial')}><SvgIcon component={BookmarksIcon}></SvgIcon>Commercial</Button>
                        <Button color="primary" className={newSegment === 'Public' ? 'filled' : ''} onClick={() => setNewSegement('Public')}><SvgIcon component={BookmarksIcon}></SvgIcon>Public</Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button className='edit-buttons cancel' onClick={onClose}>CANCEL</button>
                <button className='edit-buttons save' onClick={() => onSubmit(newSegment, newIndustry)}>
                    {isUpdating ? <Spinner isButton={true}/> : 'SAVE'}
                    </button>
            </DialogActions>
        </StyledDialog>
    )
}

export default EditDocumentMetaData
