import React, {useState, useEffect, createRef, useRef} from 'react'
import { DocumentQuestionDTO } from '../../models/document'
import EditIcon from '../../icons/EditIcon';
import "./qaPair.scss"
import QAPairEdit from './qaPairEdit';
import { updateAnswer, removeQuestion, updateQuestion } from '../../../store/actions/content-library/documentViewActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Parser, {Element, domToReact, HTMLReactParserOptions} from 'html-react-parser'
import CommentIcon from '../../icons/CommentIcon';
import DeleteIcon from '../../icons/DeleteIcon';
import AssignReviewerIcon from '../../icons/AssignReviewerIcon';
import AssignContentOwnerDialog from '../assignContentOwnerDialog/assignContentOwnerDialog';
import AlertDialog from '../alertDialog/alertDialog';
import { Popover, SvgIcon } from '@mui/material';
import { FileCopyOutlined } from '@mui/icons-material';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles'
import Snackbar from '../snackbar/snackbar';
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion"
import MuiAccordionSummary, {AccordionSummaryProps} from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import AddIcon from '@mui/icons-material/Add';
import PlusIcon from '../../icons/AddIcon'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import VerticalDotsIcon from '../../icons/VerticalDotsIcon';
import ReviewerIcon from '../../icons/ReviewerIcon';
import TagIcon from '../../icons/TagIcon';
import EditQuestionMetadata from '../editDocumentMetaData/editDocumentMetaData'
import ReviewCycleDialog from '../reviewCycleCatalog/reviewCycleCatalog';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({theme}) => ({
    boxShadow: 'none',
    border: '1px solid #DCDCDC',
    background: 'white',
    borderRadius: '4px'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<AddIcon fontSize='medium' sx={{color: "#232f3e"}}/>} 
        {...props}
    />
))(({theme}) => ({
    minHeight: '40px!important',
    padding: '0 12px',
    "& div$expanded": {
        margin: '0 0'
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: '20px 12px 20px 48px',
    borderTop: '1px solid #DCDCDC'
}))

const CopyButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#232f3e",
    backgroundColor: "white",
    border: "1px solid #232f3e",
    padding: "4px 20px",
    borderRadius: "8px",
    fontSize:"16px",
    lineHeight: "1",
    "& svg": {
        height: "18px",
        width: "18px",
        marginRight: "10px"
    }
}));

const parserOptions:HTMLReactParserOptions = {
    replace: (domNode) => { 
        const domElement: Element = domNode as Element;
        if (domElement.name === "p") {
            return (
                <p style={{fontFamily: 'AmazonEmber'}}>
                    {domToReact(domElement.children,parserOptions)}
                </p>
            )
        }
        if (domElement.name === 'li') {
            return (
                <li style={{fontFamily: 'AmazonEmber'}}>
                    {domToReact(domElement.children, parserOptions)}
                </li>
            )
        } 
    }
}

interface QAPairProps extends DocumentQuestionDTO {
    isAllowedToMakeChanges: boolean,
    hash?:string,
}

/**
 * Returns a component that holds the question & answer pair and provides editing. 
 */
const QAPair = ({question, createdBy, createdAt, updatedBy, lastUpdated, assignee, questionIdentifier, sectionIdentifier, answer, isAllowedToMakeChanges = false, hash }:QAPairProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [isAssigningReviewer, setIsAssigningReviewer] = useState<boolean>(false)
    const [showAlertDialog, setShowAlertDialog] =  useState<boolean>(false)
    const [showAnswer, setShowAnswer] = useState<boolean>(false)
    const [scroll, setScroll] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [snackbarAnchorEl, setSnackbarAnchorEl] = useState<HTMLLIElement | HTMLDivElement | null>(null);
    const [isEditingMetadata, setIsEditingMetadata] = useState<boolean>(false);
    const [showReviewCycle, setShowReviewCycle] = useState<boolean>(false);


    const handleSettingsClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseSettings = () => {
        setAnchorEl(null)
    }

    const handleCopyClick = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>) => {
        setSnackbarAnchorEl(event.currentTarget)
        handleCopy()
    }

    const handleCloseSnackbar = () => {
        setSnackbarAnchorEl(null)
    }

    const handleAssignOwnerClick = () => {
        setIsAssigningReviewer(true)
        handleCloseSettings()
    }

    const handleEditAnswerClik = () => {
        setIsEditing(true)
        handleCloseSettings()
    }

    const handleDeleteEntry = () => {
        setShowAlertDialog(true)
        handleCloseSettings()
    }

    const handleEditMetadataClick = () => {
        setIsEditingMetadata(true)
        handleCloseSettings()
    }

    const handleReviewCycleClick = () => {
        setShowReviewCycle(true)
        handleCloseSettings()
    }

    const settingsOpen = Boolean(anchorEl)
    const id = settingsOpen ? 'simple-popover' : undefined;

    const snackbarOpen = Boolean(snackbarAnchorEl)
    const snackbarId = snackbarOpen ? 'snackbar-popover' : undefined;

    const targetRef = createRef<HTMLDivElement>()

    useEffect(() => {
        if (hash === questionIdentifier) {
            setShowAnswer(true)
            setScroll(true)
        }
    }, [hash])
    

    useEffect(() => { 
        if (hash === questionIdentifier) {
            if (targetRef.current) {
                setTimeout(() => {
                    if (scroll) {
                        targetRef.current?.scrollIntoView({
                            behavior: 'smooth'
                        })
                        setScroll(false)
                    }
                    
                }, 500)    
            }
        }
    }, [targetRef])

    const dispatch = useAppDispatch()

    const isUpdating = useAppSelector((state) => state.documentViewReducer.isUpdating)
    const currentDocument = useAppSelector((state) => state.documentViewReducer.document)

    const updateQuestionAnswer = async (question:string, answer:string) => {
        if (await dispatch(updateQuestion({
            documentIdentifier: currentDocument.documentIdentifier,
            questionIdentifier: questionIdentifier,
            answer: answer,
            question: question,
            sectionIdentifier: sectionIdentifier
         }))) {
            setIsEditing(false)
         }
    }

    const updateAssignee = async (assignee:string) => {
        if (await dispatch(updateQuestion({
            documentIdentifier: currentDocument.documentIdentifier,
            questionIdentifier: questionIdentifier,
            sectionIdentifier: sectionIdentifier,
            assignee: assignee
        }))) {
            setIsAssigningReviewer(false);
        }
    }

    const deleteQuestion = async() => {
        if (await dispatch(removeQuestion({
            documentIdentifier: currentDocument.documentIdentifier,
            sectionIdentifier: sectionIdentifier,
            questionIdentifier: questionIdentifier
        }))) {
            setShowAlertDialog(false);
        }
    }

    const copyToClip = (str:string) => {
        const listener = (e:ClipboardEvent) => {
            e.clipboardData?.setData("text/html",str)
            e.clipboardData?.setData("text/plain",str)
            e.preventDefault()
        }
        document.addEventListener("copy",listener)
        document.execCommand("copy")
        document.removeEventListener("copy",listener)
    } 


    const handleCopy = () => {
        const htmlContent = document.getElementById(questionIdentifier)?.innerHTML
        if (htmlContent) {
            copyToClip(htmlContent)
            setTimeout(() => {
                handleCloseSettings()
                handleCloseSnackbar()
            }, 2000)
        }
    }


    return (
        <>
            <div className='accordion-wrapper' ref={targetRef}>
                <Accordion expanded={showAnswer}>
                    <AccordionSummary onClick={() => setShowAnswer(prev => !prev)}>
                        <div className='accordion-summary-container'>
                            <DragIndicatorIcon fontSize='medium' sx={{color: "#232f3e"}}/>
                            <div className='accordion-metadata-container'>
                                <h2>{question}</h2>
                                <div>
                                    <TagIcon height={12} width={12} fill={"#232f3e"}/>
                                    <p>Commercial/Media & Entertainment</p>
                                </div>
                            </div>
                            {isAllowedToMakeChanges && (
                                <div className='accordion-assignee-container'>
                                    {assignee && (
                                    <div>
                                        <h4>{assignee.substring(0,2).toUpperCase()}</h4>
                                        <p>{assignee}</p>
                                    </div>
                                    )}
                                    {!assignee && (
                                        <h2>Unassigned</h2>
                                    )}
                                </div>
                            )}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <div className='answer-toolbar-wrapper'>
                                <div className='answer-container'>
                                    <div id={questionIdentifier}>
                                        {answer && Parser(answer, parserOptions)}
                                    </div>
                                </div>
                                {isAllowedToMakeChanges && (
                                    <div className='toolbar-container'>
                                        <div className='icon-container' onClick={handleSettingsClick} data-testid="qa-settings"> 
                                            <VerticalDotsIcon/>
                                        </div>
                                        <Popover
                                            id={id}
                                            open={settingsOpen}
                                            anchorEl={anchorEl}
                                            onClose={handleCloseSettings}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin= {{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        >
                                           <ul className='qa-settings-list'>
                                                <li className='qa-settings-item' onClick={handleEditAnswerClik}>
                                                    <div>
                                                        <EditIcon height={14} width={14}/>
                                                        <h4>Edit entry</h4>
                                                    </div>
                                                </li>
                                                <li className='qa-settings-item' onClick={handleAssignOwnerClick}>
                                                    <div>
                                                        <AssignReviewerIcon height={14} width={14}/> 
                                                        <h4>Assign owner</h4>
                                                    </div>
                                                </li>
                                                <li className='qa-settings-item' onClick={handleCopyClick} >
                                                    <div>
                                                        <SvgIcon component={FileCopyOutlined} sx={{height: "14px", width: "14px"}}/>
                                                        <h4>Copy answer text</h4>
                                                    </div>
                                                </li>
                                                {/* <li className='qa-settings-item'>
                                                    <div>
                                                        <CommentIcon height={14} width={14}/>
                                                        <h4>Comments</h4>
                                                    </div>
                                                </li> */}
                                                <li className='qa-settings-item' onClick={handleEditMetadataClick}>
                                                    <div>
                                                        <SvgIcon component={BookmarksIcon} sx={{height: "14px", width: "14px"}}/>
                                                        <h4>Add metadata</h4>
                                                    </div>
                                                </li>
                                                {/* <li className='qa-settings-item'>
                                                    <div>
                                                        <ReviewerIcon height={14} width={14}/>
                                                        <h4>Review now</h4>
                                                    </div>
                                                </li>
                                                <li className='qa-settings-item' onClick={handleReviewCycleClick}>
                                                    <div>
                                                        <div style={{width: "14px", height: "14px"}}/>
                                                        <h4>Review cycle</h4>
                                                    </div>
                                                </li> */}
                                                <li className='qa-settings-item delete' onClick={handleDeleteEntry}>
                                                    <div>
                                                        <DeleteIcon height={14} width={14} fill={"red"}/>
                                                        <h4>Delete entry</h4>
                                                    </div>
                                                </li>
                                           </ul>
                                        </Popover>
                                    </div>
                                )}
                                {!isAllowedToMakeChanges && (
                                    <div className='toolbar-container'>
                                        <div className='icon-container' onClick={handleCopyClick}> 
                                            <SvgIcon component={FileCopyOutlined} sx={{height: "14px", width: "14px"}}/>
                                        </div>
                                        
                                    </div>
                                )}
                            </div>
                            <div className='metadata-details'>
                                <div>
                                    <p>Created by <strong>{createdBy}</strong> on {createdAt &&  new Date(createdAt).toISOString().substring(0,10)}</p>
                                    {updatedBy && lastUpdated && (
                                        <p>Updated by <strong>{updatedBy}</strong> on {lastUpdated  && new Date(lastUpdated).toISOString().substring(0,10)}</p>
                                    )}
                                    
                                </div>
                                {/* <div className='reviewed-by'>
                                    <p>Never been reviwed</p>
                                </div> */}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <QAPairEdit 
                isUpdating={isUpdating} 
                question={question} 
                answer={answer} 
                onSubmit={updateQuestionAnswer} 
                open={isEditing} 
                onClose={() => setIsEditing(false)}
            />
            <Popover
                open={snackbarOpen}
                anchorEl={snackbarAnchorEl}
                id={snackbarId}
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                    }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                    }}
            >
                <Snackbar open={true} text="Copied to clipboard"/>
            </Popover>
            <AssignContentOwnerDialog
                assignee={assignee}
                open={isAssigningReviewer}
                onSubmit={updateAssignee}
                isUpdating={isUpdating}
                onClose={() => setIsAssigningReviewer(false)}
            />
            <AlertDialog
                open={showAlertDialog}
                isUpdating={isUpdating}
                onAccept={deleteQuestion}
                onClose={() => setShowAlertDialog(false)}
            />
            <EditQuestionMetadata
                open={isEditingMetadata}
                onClose={() => setIsEditingMetadata(false)}
                isUpdating={isUpdating}
                onSubmit={() => {}}
                industry={currentDocument.industry}
                segment={currentDocument.segment}
            />
            <ReviewCycleDialog
                open={showReviewCycle}
                onClose={() => setShowReviewCycle(false)}
            />
        </>
    )
}

export default QAPair