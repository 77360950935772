import React, { useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Input from '@mui/material/Input';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver'

const axios = require('axios');

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "https://891kgsj2fh.execute-api.us-east-1.amazonaws.com/MainStage/"
});

const useStyles = makeStyles((theme) => ({
  question: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: 16
  },
  uploadButton: {
    lineHeight: '2rem',
    width: "100%",
    backgroundColor: theme.palette.secondary.main
  }
}));

export default function UploadFileComponent(props) {
  const [files, setFiles] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  function handleChange(file) {
    setFiles(file);
  }

  const handleAnswerChange = (event, index) => {
    let answersall = [...answers];
    let item = answersall[index];
    item.answer = event.target.value;

    answersall[index] = item;

    setAnswers(answersall)
  };

  function generateExcel() {

    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'RFX Sage'
    workbook.created = new Date(Date.now())
    workbook.modified = new Date()
    const sheet = workbook.addWorksheet('RFX Answers', {
      pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 }
    });

    sheet.columns = [
      { header: 'Questions', key: 'question', width: 40, style: { alignment: { wrapText: true }, font: { bold: true } } },
      { header: 'Responses', key: 'response', width: 60, style: { alignment: { wrapText: true } } }
    ];
    sheet.getCell(1, 2).style = { font: { bold: true } }
    sheet.addRows(answers.map(el => [el.question, el.answer]), { numFmt: '@' } )

    workbook
      .xlsx
      .writeBuffer()
      .then((data) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileExtension = '.xlsx';
        const blob = new Blob([data], { type: fileType });
        saveAs(blob, 'RFx_Sage_Questionnaire_Answers' + fileExtension);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file)
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        resolve(reader.result)
      }
    })
  }

  async function uploadFile() {
    setShowBackdrop(true);

    const buffer = await readFile(files[0]);
    const workbook = new ExcelJS.Workbook();
    const file = await workbook.xlsx.load(buffer);
    const sheet = file.getWorksheet(1);
    const questions = []
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 1) {

        questions.push({"question":row.getCell(1).value})
      }
    });
   
    try {
      const session = await Auth.currentSession();

      const response = await http.post("upload", {questions}, {
        headers: {
          'Authorization': `Bearer ${session.getAccessToken().getJwtToken()}`
        }
      });
      let r_json = JSON.parse(response.data.output)
      setAnswers(r_json.Payload)
    } catch (error) {
      console.error(error);
    }

    setShowBackdrop(false);
  }

  const handleBackdropClose = () => {
    setShowBackdrop(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const classes = useStyles();

  return answers.length === 0 ? (
    <div style={{ width: "50%", marginRight: "auto", marginLeft: "auto", marginTop: "2rem" }}>
      <h2 style={{ color: "#df8244", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}> Step 1 </h2>
      <span style={{ fontSize: 20 }}> Upload an excel file with all your questions. You can start by downloading the template from
        <Link to="/files/RFx_Sage_Questionnaire_Template.xlsx" target="_blank" download> here. </Link> </span>
      <DropzoneArea
        onChange={(file) => handleChange(file)}
        acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
        filesLimit={1}
        dropzoneText="Drag and drop an Excel file here or click"
        useChipsForPreview={true}
      />
      <Button className={classes.uploadButton} disabled={files && files.length === 0} variant="contained" color="secondary" onClick={() => uploadFile()}> Upload </Button>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error">File upload failed, try again later!</Alert>
      </Snackbar>
    </div>
  ) : (
    <div style={{ padding: "1rem", backgroundColor: "white", width: "50%", marginRight: "auto", marginLeft: "auto", marginTop: "2rem" }}>
      <h2 style={{ color: "#df8244", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}> Step 2 </h2>
      <p style={{ fontSize: 20 }}> Here is what we found for you. You can review and update the questions below. </p>
      <Button style={{ width: "100%", marginBottom: "20px" }} variant="contained" color="primary" onClick={generateExcel}> Download as Excel document </Button>
      {answers.map((answer, index) => {
        return (
          <div key={answer.question}>
            <p className={classes.question}> {answer.question} </p>
            <Input fullWidth multiline id="standard-basic" value={answer.answer} onChange={event => handleAnswerChange(event, index)} />
          </div>
        )
      })}
    </div>
  )

}
