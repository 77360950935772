import React from 'react'

type AssignReviewerIconProps = {
    fill?: string,
    height?: number,
    width?: number
}

const AssignReviewerIcon = ({fill="#232F3E", height=22, width=22}: AssignReviewerIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M11 10.3889C11.902 10.3901 12.784 10.1238 13.5345 9.62356C14.285 9.12335 14.8703 8.41178 15.2163 7.57886C15.5623 6.74593 15.6534 5.8291 15.4782 4.94436C15.303 4.05962 14.8693 3.24673 14.2319 2.60855C13.5946 1.97037 12.7823 1.53557 11.8978 1.35917C11.0133 1.18276 10.0963 1.27269 9.26296 1.61756C8.42957 1.96243 7.71721 2.54675 7.216 3.29659C6.7148 4.04643 6.44727 4.92808 6.44727 5.83001C6.44726 7.03804 6.92674 8.19669 7.78037 9.05147C8.63401 9.90625 9.79201 10.3873 11 10.3889ZM11 2.48723C11.6605 2.48602 12.3064 2.68084 12.8561 3.04701C13.4057 3.41318 13.8344 3.93424 14.0877 4.54418C14.341 5.15413 14.4076 5.82552 14.279 6.47334C14.1504 7.12116 13.8325 7.71626 13.3655 8.18327C12.8985 8.65028 12.3034 8.96819 11.6556 9.09674C11.0078 9.22529 10.3364 9.1587 9.72644 8.9054C9.1165 8.6521 8.59544 8.22348 8.22927 7.67383C7.8631 7.12418 7.66828 6.47823 7.66949 5.81778C7.6711 4.93496 8.02252 4.08876 8.64677 3.46451C9.27102 2.84026 10.1172 2.48884 11 2.48723Z"/>
            <path d="M3.66645 19.4883V15.7484C4.60993 14.7581 5.74915 13.9751 7.01166 13.4491C8.27417 12.923 9.63234 12.6655 10.9998 12.6928C12.8714 12.6676 14.7135 13.1604 16.3226 14.1167L17.1476 13.1939C15.308 12.0352 13.1737 11.4306 10.9998 11.4522C9.40919 11.4117 7.82924 11.7226 6.37258 12.3627C4.91591 13.0029 3.61841 13.9565 2.57257 15.1556C2.49104 15.2606 2.44599 15.3893 2.44423 15.5222V19.4883C2.43601 19.8212 2.56 20.1438 2.78907 20.3855C3.01814 20.6271 3.33362 20.7682 3.66645 20.7778H11.2381L10.077 19.5556L3.66645 19.4883Z"/>
            <path d="M18.3331 19.4883V19.5556H16.4081L15.3081 20.7778H18.3331C18.6616 20.7682 18.9734 20.6305 19.2018 20.3941C19.4302 20.1578 19.5571 19.8415 19.5553 19.5128V16.0111L18.3331 17.3739V19.4883Z"/>
            <path d="M21.2421 11.3789C21.1213 11.2713 20.9627 11.2161 20.8012 11.2252C20.6397 11.2344 20.4884 11.3072 20.3805 11.4278L13.2794 19.3722L10.1016 15.9561C10.0494 15.8951 9.98575 15.845 9.91422 15.8086C9.84268 15.7722 9.76468 15.7503 9.68467 15.744C9.60465 15.7378 9.52419 15.7474 9.44788 15.7722C9.37157 15.7971 9.3009 15.8367 9.23991 15.8889C9.18017 15.9432 9.13179 16.0089 9.09758 16.0821C9.06336 16.1552 9.04399 16.2344 9.04058 16.3151C9.03717 16.3958 9.04979 16.4764 9.07771 16.5522C9.10563 16.628 9.1483 16.6975 9.20325 16.7567L13.2916 21.1567L21.291 12.2222C21.393 12.1024 21.4449 11.9478 21.4358 11.7907C21.4266 11.6337 21.3573 11.4861 21.2421 11.3789Z"/>
        </svg>
    )
}

export default AssignReviewerIcon