import React from 'react'
import "./documentListItem.scss"
import TagIcon from '../../icons/TagIcon'

export type DocumentListItemProps = {
    name: string;
    documentIdentifier:string;
    lastUpdated: Date;
    onClick: (document:string) => void;
    segment?: string;
    industry?: string;
}

/**
 * Component for holding Document items in DocumentListView
 */
const DocumentListItem = ({name, documentIdentifier, lastUpdated, onClick, segment, industry}: DocumentListItemProps) => (
    <div className="document-item-wrapper" data-testid="document-list-item" onClick={() => onClick(documentIdentifier)}>
        <h2 className='document-item-title'>{name}</h2>
        <h4 className='document-item-metadata'>{`Last updated at:  ${lastUpdated.toISOString().substring(0,10)}`}</h4>
        <div className='document-item-tags-wrapper'>
            {segment && (
                <div className='document-item-tag-container'>
                    <TagIcon/>
                    <h3>{segment.toUpperCase()}</h3>
                </div>
            )}
            {industry && (
                <div className='document-item-tag-container'>
                    <TagIcon/>
                    <h3>{industry.toUpperCase()}</h3>
                </div>
            )}
        </div>
    </div>
)

export default DocumentListItem