export default class AvailableSourceResponse {
    constructor() {
        this.Name = '';
        this.Id = '';
        this.Type = '';
        this.UpdatedAt = new Date();
    }

    Name: string;
    Id: string;
    Type: string;
    UpdatedAt: Date;

    static parseFriendlyName(input: string): string {
        switch (input) {
            case "Rfx-Data-S3-Knowledgebase":
                return "Standard Content";
            case "Rfx-AWS-Documentation-Knowledgebase-enterprise":
                return "AWS Public Documentation";
            case "Rfx-Data-S3-Seeker":
                return "Seeker";
            case "Rfx-Data-S3-Sherpa":
                return "SHERPA";
            default:
                return input;
        }
    }
}