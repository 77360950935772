import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import AWSBackgroundImage from '../../assets/images/aws1.jpg';
import { useHistory } from "react-router-dom";
import { resetLoginSuccess } from '../../store/slices/loginSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import NDAPopUp from '../../components/ndaPopUp/ndaPopUp';

const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  button:{
    width:'350px',
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useAppDispatch()
  const loginSuccess = useAppSelector((state) => state.loginReducer.loginSuccess)
  const onAcknowledge = () => {
    dispatch(resetLoginSuccess())
  }

  return (
    <React.Fragment>
      <CssBaseline />
     
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Amazon RfX Sage
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              A tool for autocompleting RfX documents using machine learning
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button className={classes.button} variant="contained" color="primary" onClick={() => history.push('/search')}>
                    Get answers to your questions
                  </Button>
                </Grid>
                <Grid item>
                  <Button className={classes.button} variant="outlined" color="primary" onClick={() => history.push('/document')}>
                    Autocomplete an RfX document
                  </Button>
                </Grid>
                <Grid item>
                  <Button className={classes.button} variant="outlined" color="primary" href="https://kiku.aws.training/Details/eLearning?id=11479" target="_blank">
                  RFX and Vendor Form Training
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth="md">
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={AWSBackgroundImage}
                    title="Image title"
                  />
                </Card>  
        </Container>
      </main>
      <NDAPopUp
        open={loginSuccess}
        onSubmit={onAcknowledge}
      />
    </React.Fragment>
  );
}