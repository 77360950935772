import React, { useState } from 'react'

import { styled } from '@mui/material/styles';
import {
    Dialog, 
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from '../spinner/spinner';
import AssignReviewerIcon from '../../icons/AssignReviewerIcon';
import "./assignContentOwnerDialog.scss"

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        width: "auto",
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))

type AssignReviewerProps = {
    open: boolean,
    assignee: string,
    onSubmit: (reviewer:string) => void,
    onClose: () => void,
    isUpdating: boolean
}


const AssignContentOwnerDialog = ({assignee, open, isUpdating, onSubmit, onClose}:AssignReviewerProps) => {
    const [newAssignee, setNewAssignee] = useState<string>(assignee)

    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogContent>
                <div className='assignee-container'>
                    <div className='review-dialog-header'>
                        <AssignReviewerIcon/>
                        <h2>Assign Owner</h2>
                    </div>
              
                    <TextField 
                        id="outlined-basic" 
                        label="Assignee" 
                        variant="outlined" 
                        value={newAssignee} 
                        onChange={(newValue) => setNewAssignee(newValue.target.value)} 
                        sx={{width: "100%"}}
                    />    
                    
                </div>

            </DialogContent>
            <DialogActions>
                <button className='edit-buttons cancel' onClick={onClose}>CANCEL</button>
                <button className='edit-buttons save' onClick={() => onSubmit(newAssignee)}>
                    {isUpdating ? <Spinner isButton={true}/> : 'ADD'}
                </button>
            </DialogActions>
        </StyledDialog>
    )
}

export default AssignContentOwnerDialog