import React, { useState, useEffect, createRef } from 'react'
import { styled } from "@mui/material/styles"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion"
import MuiAccordionSummary, {AccordionSummaryProps} from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import "./section.scss"
import EditIcon from '../../icons/EditIcon';
import AddIcon from '@mui/icons-material/Add';
import QAPairEdit from '../qaPair/qaPairEdit';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addQuestion, loadSection, updateSection } from '../../../store/actions/content-library/documentViewActions';
import EditSectionTitle from '../editTitle/editTitle';
import { DocumentSectionDTO } from '../../models/document';
import QAPair from '../qaPair/qaPair';
import Spinner from '../spinner/spinner';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props}/>
))(({theme}) => ({
    boxShadow: 'none',
    border: '1px solid #DCDCDC',
    background: 'white',
    borderRadius: '4px'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon fontSize='large' sx={{color: "#232f3e;"}}/>} 
        {...props}
    />
))(({theme}) => ({
    height: '60px!important',
    minHeight: '60px!important',
    padding: '0 20px',
    "& div": {
            margin: '0 0!important'
    },
    "& div$expanded": {
        margin: '0 0'
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: '20px 20px 40px 20px',
    borderTop: '1px solid #DCDCDC'
}))


interface SectionProps extends DocumentSectionDTO {
    hash?: string,
    isAllowedToMakeChanges: boolean
}


/**
 * Returns a component that holds multiple question & answer pairs. 
 */
const Section = ({name, sectionIdentifier, questions, index, isAllowedToMakeChanges, hash}:SectionProps) => {
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
    const [showEditTitleDialog, setShowEditTitleDialog] = useState<boolean>(false)
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false)
    const [isHoveringEdit, setIsHoveringEdit] = useState<boolean>(false)
    const [isLoadingSection, setIsLoadingSection] = useState<boolean>(false)

    useEffect(() => {
        if(questions.find((q) => q.questionIdentifier === hash)) {
            setAccordionOpen(true)
        }
    }, [hash])

    const handleClick = () => {
        if (!isHoveringEdit) {
            setAccordionOpen(prev => !prev)
        }
    }

    const dispatch = useAppDispatch()

    const isUpdating = useAppSelector((state) => state.documentViewReducer.isUpdating)
    const document = useAppSelector((state) => state.documentViewReducer.document)

    const getSection = async() => {
        setIsLoadingSection(true);
        await dispatch(loadSection({
            documentIdentifier: document.documentIdentifier,
            sectionIdentifier: sectionIdentifier
        }));
        setIsLoadingSection(false);
    }

    const updateSectionName = async (newName: string) => {
        if (await dispatch(updateSection({
            documentIdentifier: document.documentIdentifier,
            sectionIdentifier: sectionIdentifier,
            name: newName
        }))) {
            setShowEditTitleDialog(false);
        }
    }

    const addNewQuestion = async(question:string, answer:string) => {
        if (await dispatch(addQuestion({
            documentId: document.documentIdentifier,
            question: question,
            answer: answer,
            sectionIdentifier: sectionIdentifier
        }))) {
            setShowEditDialog(false)
        }
    }
    return(
        <div className='section-wrapper'>
            <Accordion expanded={accordionOpen} onChange={(e,expanded) => {
                if (expanded && questions.length === 0) getSection()
            }}>
                <AccordionSummary onClick={handleClick}>
                    <div className='section-title-container'>
                        <h2>{name}</h2>
                        {isAllowedToMakeChanges && (
                            <div 
                                onMouseEnter={() => setIsHoveringEdit(true)}
                                onMouseLeave={() => setIsHoveringEdit(false)}
                                onClick={() => setShowEditTitleDialog(true)}
                                >
                                <EditIcon/>
                            </div>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {!isLoadingSection ?
                    <>
                        {questions && questions.map((question, index) => {
                            return (
                                <QAPair 
                                    key={index}
                                    {...question}
                                    isAllowedToMakeChanges={isAllowedToMakeChanges}
                                    hash={hash}
                                />
                            )
                        })}
                        {isAllowedToMakeChanges && (
                            <button className='add-new-question' onClick={() => setShowEditDialog(true)}>
                                <AddIcon fontSize='medium'/>
                                ADD NEW QUESTION
                            </button>
                        )}
                    </> 
                    : <Spinner/> }
                </AccordionDetails>
            </Accordion>
            <QAPairEdit
                isUpdating={isUpdating}
                open={showEditDialog}
                onSubmit={addNewQuestion}
                onClose={() => setShowEditDialog(false)}
            />
            <EditSectionTitle
                isUpdating={isUpdating}
                open={showEditTitleDialog}
                onSubmit={updateSectionName}
                onClose={() => setShowEditTitleDialog(false)}
                title={name}
                label={"Edit section title"}
            />

        </div>
    )
}

export default Section