import React from 'react'
import {styled} from '@mui/material/styles'
import {
    Dialog, 
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material'
import "./ndaPopUp.scss"

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '0px 0px'
    },
    '& .MuiPaper-root': {
        minWidth: '800px',
        height: 'auto !important',
        width: 'auto !important'
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))

type NDAPopUpProps = {
    open: boolean,
    onSubmit: () => void
}

const NDAPopUp = ({open, onSubmit}:NDAPopUpProps) => {
    return (
        <StyledDialog open={open}>
            <DialogContent>
                <div className='nda-wrapper'>
                    <div className='nda-title-container'>
                        <h2>RFX Sage Usage Term</h2>
                    </div>
                    <div className='nda-content-container'>
                        <p>
                            You must perform a thorough review before reusing any content in RFx Sage. 
                            Each document types includes rules and guidelines that you must follow before re-using the content.
                        </p>
                        <ul>
                            <li>
                                <p><strong>Standard Proposal Content: </strong>Standard Proposal Content is standardized content created specifically for RFX responses and proposal creation. 
                                    They do not contain sensitive or NDA-level information. This content is reviewed every three months for accuracy. You can share this content with your customers.
                                </p>
                            </li>
                            <li>
                                <p><strong>RFxAids: </strong>RFxAids contain curated, generic AWS content. You can use this content in partner and customer-facing document. 
                                    Each RFxAid contains its own usage rules that you must follow.
                                </p>
                            </li>
                            <li>
                                <p><strong>PathFinder/SHERPA: </strong>PathFinder/SHERPA content is created by the legal team. It includes both NDA-level and non-NDA level documentation. 
                                    Please follow each document’s rules of use.
                                </p>
                            </li>
                            <li>
                                <p><strong>AWS Seeker: </strong>The statements provided in Seeker have been validated as stated and should not be modified. Additionally, avoid making assertions to customers not supported by Seeker content. 
                                    These statements retrieved from Seeker should be used to validate or supplement information found in other public resources (AWS Whitepapers, User Guides, Service Documentation, etc). As RFx Sage it not (yet) a replacement for finding content in Seeker, you must ensure to verify the statements with the original source (Seeker). 
                                    This information can only be shared with customers under NDA.
                                </p>
                            </li>
                            <li>
                                <p><strong>AWS Whitepapers: </strong>AWS Whitepapers are public and published on the AWS public website. You can share this content with your customers.</p>
                            </li>
                        </ul>
                    </div>
                    <div className='nda-acknowledge-container'>
                        <p>I acknowledge I have read, understand, and will follow RFx Sage’s use terms.</p>
                        <button onClick={onSubmit}>Acknowledge</button>
                    </div>
                </div>
            </DialogContent>
        </StyledDialog>
    )
}

export default NDAPopUp