import { Component } from 'react';
import { Button, SvgIcon} from "@mui/material";
import { DocumentQuestionDTO, DocumentSectionDTO } from '../../models/document';
import './documentView.scss';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {RouteComponentProps} from 'react-router-dom'
import withPermissions, {injectedPermissionProps} from '../../components/withPermissions/withPermissions';
import DocumentDetails from "../../components/documentDetails/documentDetails"
import EditDocumentOwners from '../../components/editDocumentOwners/editDocumentOwners';
import EditDocumentMetaData from '../../components/editDocumentMetaData/editDocumentMetaData';
import Spinner from '../../components/spinner/spinner';
import Section from '../../components/section/section'

import {connect} from 'react-redux'

import {RootState} from '../../../store/store'
import { loadDocument, updateOwners, updateTags, addSection } from '../../../store/actions/content-library/documentViewActions'
import { DocumentState } from '../../../store/slices/content-library/documentViewSlice';
import {compose} from 'redux'
import CreateSectionDialog from '../../components/addDialog/addDialog';


const mapStateToProps = (state:RootState) => {
    return {
        document: state.documentViewReducer.document,
        isLoading: state.documentViewReducer.isLoading,
        isUpdating: state.documentViewReducer.isUpdating
    }
}

const mapDispatchToProps = {
    loadDocument,
    addSection,
    updateOwners,
    updateTags
}

type MatchParams =  {
    documentId: string;
}

export interface DocumentViewProps extends RouteComponentProps<MatchParams>, injectedPermissionProps, DocumentState{
    loadDocument: typeof loadDocument,
    addSection: typeof addSection,
    updateOwners: typeof updateOwners,
    updateTags: typeof updateTags
} 

type DocumentViewState = {
    AssignContentOwnerDialog: boolean;
    AssignTagsDialogOpen: boolean;
    CreateSectionDialogOpen: boolean;
}


class DocumentView extends Component<DocumentViewProps, DocumentViewState> {
    state: DocumentViewState = {
            AssignContentOwnerDialog: false,
            AssignTagsDialogOpen: false,
            CreateSectionDialogOpen: false
    }

    componentDidUpdate(prevProps: DocumentViewProps, prevState:DocumentViewState) {
        if (prevProps.isUpdating !== this.props.isUpdating) {
            if (!this.props.isUpdating) {
                if (this.state.AssignContentOwnerDialog) this.closeOwnerDialog()
                if (this.state.AssignTagsDialogOpen) this.closeTagsDialog()
                if (this.state.CreateSectionDialogOpen) this.closeSectionDialog()
            }
        }
    }

    sortSections = (a: DocumentSectionDTO, b: DocumentSectionDTO) => {
        if (a.index < b.index) return -1;
        if (a.index > b.index) return 1;
        return 0;
    }


    closeOwnerDialog = async () => {
        this.setState({
            AssignContentOwnerDialog: false
        })
    }

    closeTagsDialog = async () => {
        this.setState({
            AssignTagsDialogOpen: false,
        })
    }

    closeSectionDialog = () => {
        this.setState({
            CreateSectionDialogOpen: false
        })
    }

    isAllowedToMakeChanges = () => {
        const {document, userPermissions: {permissions,alias}} = this.props
        return permissions === 'admin' || (permissions === 'content-owner' && [document.ownerOne,document.ownerTwo].includes(alias))
    }

    addSection = (section: string) => {
        const {document: {documentIdentifier}} = this.props;
        this.props.addSection({
            section: section,
            documentIdentifier: documentIdentifier
        });
    }


    updateTags = (segment:string, industry:string) => {
        const {document: {documentIdentifier, ownerOne, ownerTwo, name}} = this.props
        this.props.updateTags({
            documentIdentifier: documentIdentifier,
            ownerOne: ownerOne,
            ownerTwo: ownerTwo,
            industry: industry,
            segment: segment,
            name: name
        })
    }

    updateOwners = (ownerOne:string, ownerTwo:string) => {
        const {document:{documentIdentifier, industry, segment, name}} = this.props
        this.props.updateOwners({
            documentIdentifier: documentIdentifier,
            ownerOne: ownerOne,
            ownerTwo: ownerTwo,
            industry: industry,
            segment: segment,
            name: name
        })
    }


    componentDidMount() {
        this.props.loadDocument({name: this.props.match.params.documentId, questionIdentifier: this.props.location.hash.replace("#", '')})
    }
    

    render() {
        const {isLoading, document, isUpdating, location: {hash}} = this.props
        return (
            !isLoading ? 
                <div className="contentLibrary">
                    <div className="leftGutter">
                        {this.isAllowedToMakeChanges() && (
                            <>
                            <Button className="gutterButton" color="primary" onClick={() => {
                                this.setState({ ...this.state, AssignContentOwnerDialog: true })
                            }}>
                                <SvgIcon component={AccountCircleIcon}></SvgIcon> Assign Document
                            </Button>
                            </>
                        )}
                        <Button className="gutterButton" color="primary">
                            <SvgIcon component={ManageSearchIcon}></SvgIcon> Search Document
                        </Button>
                        {this.isAllowedToMakeChanges() && (
                            <>
                            <Button className="gutterButton" color="primary" onClick={() => {
                                this.setState({ ...this.state, AssignTagsDialogOpen: true })
                            }}>
                                <SvgIcon component={BookmarksIcon}></SvgIcon> Add Metadata
                            </Button>
                            </>
                        )}
                        <Button className="gutterButton" color="primary">
                            <SvgIcon component={AccessTimeIcon}></SvgIcon> Version History
                        </Button>
                    </div>
                    <div className="main document-wrapper">
                        <DocumentDetails
                            document={document}
                            isAllowedToMakeChanges={this.isAllowedToMakeChanges()} 
                        />
                        <div className='document-sections-container'>
                            {[...document.sections].sort(this.sortSections).map((section, index) => {
                                return (
                                    <Section 
                                        key={index}
                                        isAllowedToMakeChanges={this.isAllowedToMakeChanges()} 
                                        hash={hash.replace("#", '')}
                                        {...section}
                                    />
                                )
                            })}
                            
                        </div>
                        {this.isAllowedToMakeChanges() && (
                            <button className="save-btn" onClick={() => this.setState({CreateSectionDialogOpen: true})}>
                                    + Add new section
                            </button>
                        )}
                    </div>
                    <CreateSectionDialog
                        label='Create new section'
                        onSubmit={this.addSection}
                        placeholder="New section name"
                        onClose={this.closeSectionDialog}
                        open={this.state.CreateSectionDialogOpen}
                        isUpdating={isUpdating}
                    />
                    <EditDocumentOwners
                        ownerOne={document.ownerOne}
                        ownerTwo={document.ownerTwo}
                        documentName={document.name}
                        isUpdating={isUpdating}
                        onSubmit={this.updateOwners}
                        open={this.state.AssignContentOwnerDialog}
                        onClose={this.closeOwnerDialog}
                    />
                    <EditDocumentMetaData
                        open={this.state.AssignTagsDialogOpen}
                        onClose={this.closeTagsDialog}
                        industry={document.industry}
                        isUpdating={isUpdating}
                        segment={document.segment}
                        onSubmit={this.updateTags}
                    />
                </div>
            : <Spinner/>
        )
    }
}



export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions
    )(DocumentView)