import React, { ChangeEvent, SyntheticEvent, useState } from 'react'

import { styled } from '@mui/material/styles';
import {
    Dialog, 
    DialogActions,
    DialogContent,
    Select,
    SelectChangeEvent,
    MenuItem,
    FormControl,
    SelectProps,
} from '@mui/material'
import { OutlinedInput } from '@material-ui/core';
import "./reviewCycleCatalog.scss"

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        width: "auto"
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))


const StyledSelect = styled((props: SelectProps) => (
    <Select
        {...props}
    />
))(({theme}) => ({
    "& .MuiSelect-select": {
        padding: "8px",
        display: "flex",
        alignItems: "center"
    }
}));




type AssignReviewerProps = {
    contentOwners?:string[],
    open: boolean,
    onSubmit?: (reviewer:string) => void,
    onClose: () => void
}

const freqArray = ["1","2","3","4","5","6","7","8","9","10","11","12"]
const freqTypeArray = ["weeks", "months", "years"]

const defaultContentOwners = ['Christina Carter', 'James Eastham', 'Elias Bonnici']

const ReviewCycleDialog = ({contentOwners=defaultContentOwners, open, onSubmit, onClose}:AssignReviewerProps) => {
    const [reviewFrequency, setReviewFrequency] = useState<string>("3");
    const [reviewFrequencyType, setReviewFrequencyType] = useState<string>("weeks")
    const [reviewStartDate, setReviewStartDate] = useState<string>("")
    const [reviewer, setReviewer] = useState<string>("");
    const [reviewFrequencyComplete, setReviewFrequencyComplete] = useState<string>("3")
    const [reviewFrequencyCompleteType, setReviewFrequencyCompleteType] = useState<string>("weeks")

    const handleReviewFrequencyChange = (event: SelectChangeEvent<unknown>) => {
        setReviewFrequency(event.target.value as string)
    }

    const handleReviewFrequencyTypeChange = (event: SelectChangeEvent<unknown>) => {
        setReviewFrequencyType(event.target.value as string)
    }

    const handleReviewStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setReviewStartDate(event.target.value)
    }

    const handleReviewerChange = (event: ChangeEvent<HTMLInputElement>) => {
        setReviewer(event.target.value)
    }

    const handleReviewFrequencyCompleteChange = (event: SelectChangeEvent<unknown>) => {
        setReviewFrequencyComplete(event.target.value as string)
    }

    const handleReviewFrequencyCompleteTypeChange = (event: SelectChangeEvent<unknown>) => {
        setReviewFrequencyCompleteType(event.target.value as string)
    }


    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogContent>
                <div className='review-cycle-container'>
                    <h2>Review Cycle</h2>
                    <p>Review Cycles are a great way of reviewing your Library Entries on a recurring schedule</p>
                    <div className='review-cycle-content'>
                        <div className='review-cycle-section'>
                            <div className='section-content'>
                                <h2>Review Frequency*</h2>
                                <div>
                                    <FormControl sx={{width: "30%"}}>
                                        <StyledSelect
                                            value={reviewFrequency}
                                            onChange={handleReviewFrequencyChange}
                                            input={<OutlinedInput/>}
                                        >
                                            {freqArray.map((number) => {
                                                return(
                                                    <MenuItem value={number} key={number}>{number}</MenuItem>
                                                )
                                            })}
                                        </StyledSelect>
                                    </FormControl>
                                    <FormControl sx={{width: "65%"}}>
                                        <StyledSelect
                                            value={reviewFrequencyType}
                                            onChange={handleReviewFrequencyTypeChange}
                                            input={<OutlinedInput/>}
                                        >
                                            {freqTypeArray.map((type:string, index) => {
                                                return(
                                                    <MenuItem value={type} key={index}>{type}</MenuItem>
                                                )
                                            })}
                                        </StyledSelect>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='section-content'>
                                <h2>Review Start Date*</h2>
                                <div>
                                    <input className='review-input' type={"date"} value={reviewStartDate} onChange={handleReviewStartDateChange}/>
                                </div>
                            </div>
                        </div>
                        <div className='review-cycle-section'>
                            <div className='section-content'>
                                <h2>Reviewer*</h2>
                                <div>
                                    <input className='review-input' type="text" value={reviewer} onChange={handleReviewerChange}/>
                                </div>
                            </div>
                            <div className='section-content'>
                                <h2>Time to Complete*</h2>
                                <div>
                                <FormControl sx={{width: "30%"}}>
                                        <StyledSelect
                                            value={reviewFrequencyComplete}
                                            onChange={handleReviewFrequencyCompleteChange}
                                            input={<OutlinedInput/>}
                                        >
                                            {freqArray.map((number) => {
                                                return(
                                                    <MenuItem value={number} key={number}>{number}</MenuItem>
                                                )
                                            })}
                                        </StyledSelect>
                                    </FormControl>
                                    <FormControl sx={{width: "65%"}}>
                                        <StyledSelect
                                            value={reviewFrequencyCompleteType}
                                            onChange={handleReviewFrequencyCompleteTypeChange}
                                            input={<OutlinedInput/>}
                                        >
                                            {freqTypeArray.map((type:string, index) => {
                                                return(
                                                    <MenuItem value={type} key={index}>{type}</MenuItem>
                                                )
                                            })}
                                        </StyledSelect>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                <button className='action-buttons cancel' onClick={onClose}>Cancel</button>
                <button className='action-buttons save'>Create Review Cycle</button>
            </DialogActions>
        </StyledDialog>
    )
}

export default ReviewCycleDialog