import React from 'react'

import { styled } from '@mui/material/styles';
import {
    Dialog, 
    DialogContent,
} from '@mui/material'
import AlertIcon from '../../icons/AlertIcon';
import "./alertDialog.scss"
import Spinner from '../spinner/spinner';

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        width: 'auto'
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        marginBottom: '20px',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '20px 20px'
    }
}))

type AlertDialogProps = {
    onAccept: () => void,
    onClose: () => void,
    open: boolean,
    isUpdating: boolean
}

const AlertDialog = ({onAccept, onClose, open, isUpdating}:AlertDialogProps) => {
    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogContent>
                <div className='alert-container'>
                    <AlertIcon height={30} width={30}/>
                    <h4>Are you sure you want to delete this response?</h4>
                    <div className='alert-buttons-container'>
                        <button className='edit-buttons save' onClick={onAccept}>
                            {isUpdating ? <Spinner isButton={true}/> : 'YES'}
                        </button>
                        <button className='edit-buttons cancel' onClick={onClose}>NO</button>
                    </div>
                </div>
            </DialogContent>
        </StyledDialog>
    )
}

export default AlertDialog