import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
    Dialog, 
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material'
import Spinner from '../spinner/spinner'
import "./addDialog.scss"

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: '20px 20px'
    },
    '& .MuiPaper-root': {
        minWidth: '300px',
        maxHeight: '300px',
    },
    '& .MuiTypography-root': {
        padding: '0 0',
        fontSize: '22px',
        color: '#232f3e'
    },
    '& .MuiDialogActions-root': {
        padding: '0px 20px 20px 20px'
    }
}))

type AddDialogProps = {
    label?: string,
    placeholder?: string,
    onSubmit: (name:string) => void,
    onClose: () => void,
    open: boolean,
    isUpdating: boolean,
}

const AddDialog = ({label, placeholder, onSubmit,onClose,open, isUpdating}:AddDialogProps) => {
    const [newTitle, setNewTitle] = useState<string>("")

    useEffect(() => {
        return () => {
            // Reset state onClose
            setNewTitle("")
        }
    }, [open])

    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogContent>
                {label && (
                    <DialogTitle>{label}</DialogTitle>
                )}
                <div className='text-input-container'>
                    <input value={newTitle} onChange={e => setNewTitle(e.target.value)} placeholder={placeholder}/>
                </div>
            </DialogContent>
            <DialogActions>
                <button className='edit-buttons save' onClick={() => onSubmit(newTitle)} >
                    {isUpdating ? <Spinner isButton={true}/> : 'CREATE'}
                </button>
            </DialogActions>
        </StyledDialog>
    )
}

export default AddDialog