import { Route, Switch } from "react-router-dom";
import HomeView from "../views/home/home";
import SearchView from "../views/search/search";
import SupportView from "../views/support/support";
import DocumentUploadView from "../views/documentPreFillView";
import Amplify from 'aws-amplify';
import ConfigureAuthentication from '../AuthenticationConfig';
import ViewDocument from '../views/viewDocument/viewDocument';
import DocumentListView from "../content-library/views/documentList/documentList";
import DocumentView from "../content-library/views/documentView/documentView";

Amplify.configure(ConfigureAuthentication());

const ROUTES = [
    {
        path: "/",
        key: "APP",
        exact: true,
        component: HomeView,
    },
    {
        path: "/app",
        key: "APP_ROOT",
        exact: true,
        component: HomeView,
    },
    {
        path: "/search",
        key: "APP_SEARCH",
        exact: true,
        component: SearchView,
    },
    {
        path: "/support",
        key: "APP_SUPPORT",
        exact: true,
        component: SupportView,
    },
    {
        path: "/document",
        key: "APP_DOCUMENT",
        exact: true,
        component: DocumentUploadView,
    },
    {
        path: "/view-document/:documentId",
        key: "APP_DOCUMENT_VIEW",
        exact: true,
        component: ViewDocument,
    },
];

if (process.env["REACT_APP_ENABLE_CONTENT_LIBRARY"] === 'true') {
    ROUTES.push(
    {
        path: "/content-library/home",
        key: "APP_CONTENT_LIBRARY_HOME",
        exact: true,
        component: DocumentListView,
    });

    ROUTES.push(
    {
        path: "/content-library/home/:documentId",
        key: "APP_CONTENT_LIBRARY_VIEW_DOCUMENT",
        exact: true,
        component: DocumentView,
    });
}

export default ROUTES;

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={props => <route.component {...props} routes={route.routes} />}
        />
    );
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, i) => {
                return <RouteWithSubRoutes key={route.key} {...route} />
            })}
            <Route component={() => <h1>Not Found!</h1>} />
        </Switch>
    );
}
