import React, { useEffect, useState } from "react";
import MockDataWarning from "../../services/helpers/MockDataWarning";
import KendraSearch from "../../search/Search";
import { kendraClient } from "../../services/Kendra";
import { s3Client } from "../../services/Kendra";
import { indexId, errors } from "../../services/Kendra";
import { facetConfiguration } from "../../search/configuration";
import { Auth } from "aws-amplify";
import "../../search/search.scss";

export default function Search() {
  const [accessKeyId, setAccessKeyId] = useState<string>("");
  const [accessSecret, setAccessKeySecret] = useState<string>("");
  const [sessionToken, setSessionToken] = useState<string>("");

  useEffect(() => {
    async function currentTemporaryCredentials() {
      const credentials = await Auth.currentCredentials();
      if (credentials) {
        setAccessKeyId(credentials.accessKeyId)
        setAccessKeySecret(credentials.secretAccessKey)
        setSessionToken(credentials.sessionToken)
      }
    }
    currentTemporaryCredentials();
  }, [])

  return (
    <div>
      <h2 className="searchHeader">
        Search the RFx Sage Library
      </h2>
      {errors.length > 0 &&
        <MockDataWarning errors={errors} />
      }

      <KendraSearch
        kendra={kendraClient(accessKeyId, accessSecret, sessionToken)}
        s3={s3Client(accessKeyId, accessSecret, sessionToken)}
        indexId={indexId || ""}
        facetConfiguration={facetConfiguration}
      />
    </div>
  );
}
