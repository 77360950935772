import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify from "aws-amplify";
import ConfigureAuthentication from "./AuthenticationConfig";

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux';
import { setupStore } from './store/store'
import {theme} from './theme'



Amplify.configure(ConfigureAuthentication());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={setupStore()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
