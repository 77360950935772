import React from 'react'
import CheckmarkIcon from '../../icons/CheckmarkIcon'
import "./snackbar.scss"

type SnackbarProps = {
    text: string,
    type?: string,
    open: boolean
}

const Snackbar = ({text, open}:SnackbarProps) => {
    return (
        <div className={`snackbar-container ${open && "open"}`}>
            <CheckmarkIcon height={18} width={18}/>
            <p>{text}</p>
        </div>

    )
}

export default Snackbar