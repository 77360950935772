import axios, { AxiosRequestHeaders } from "axios";
import { Auth } from 'aws-amplify';
import { DocumentDTO, DocumentSectionDTO, DocumentQuestionDTO } from "../models/document";
import { UpdateMetadaProps } from "../models/update-metadata";
import { UserPermission} from "../models/permission"
import { UpdateQuestionProps } from "../models/update-question";
import { StartRowIndex } from "aws-sdk/clients/databrew";

class ContentLibraryAdminApi {
    private http = axios.create({
        baseURL: process.env.REACT_APP_CONTENT_LIBRARY_URL ?? "https://y4ye55iijj.execute-api.eu-west-1.amazonaws.com/prod/",
    });

    async listDocuments(): Promise<DocumentDTO[]> {
        let headers = await this.setDefaultHeaders();

        const apiResponse = await this.http.get<DocumentDTO[]>("document", {
            headers: headers
        });


        if (apiResponse.data === null) {
            return [];
        }

        return apiResponse.data;
    }

    async createDocument(name: string): Promise<DocumentDTO> {
        let headers = await this.setDefaultHeaders();

        console.log(`Attempting to create ${name}`);

        const res = await this.http.post<DocumentDTO>(`document`, {
            name: name,
        }, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Create document API returned an error: ${res.status}`);
        }

        return res.data;
    }

    async getDocument(name: string): Promise<DocumentDTO> {
        let headers = await this.setDefaultHeaders();
        const res = await this.http.get<DocumentDTO>(`document/${name}`, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Get Document API returned an error: ${res.status}`);
        }

        console.log(res.data);

        return res.data
    }

    async getSection(documentIdentifier: string, sectionIdentifier: string): Promise<DocumentSectionDTO> {
        let headers = await this.setDefaultHeaders();
        const res = await this.http.get<DocumentSectionDTO>(`document/${documentIdentifier}/section/${sectionIdentifier}`, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Get Document Section API returned an error: ${res.status}`);
        }

        console.log(res.data);

        return res.data;
    }

    async addDocumentSection(documentIdentifier: string, section: string, index:number): Promise<DocumentDTO> {
        let headers = await this.setDefaultHeaders();

        const res = await this.http.post<DocumentDTO>(`document/${documentIdentifier}/section`, {
            section: section,
            index: index
        }, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Add document section API returned an error: ${res.status}`);
        }

        return res.data;
    }

    async updateDocumentSection(documentIdentifier: string, sectionIdentifier: string, name: string): Promise<DocumentSectionDTO> {
        let headers = await this.setDefaultHeaders();

        const res = await this.http.put<DocumentSectionDTO>(`document/${documentIdentifier}/section/${sectionIdentifier}`, {
            name: name
        }, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Update document section API returned an error: ${res.status}`);
        }


        return res.data;
    }

    async addDocumentQuestion(documentIdentifier: string, question: string, answer: string, sectionIdentifier: string): Promise<DocumentQuestionDTO> {
        let headers = await this.setDefaultHeaders();
        const user = await Auth.currentUserInfo();
        const res = await this.http.post<DocumentQuestionDTO>(`document/${documentIdentifier}/question`, {
            question: question,
            answer: answer,
            sectionIdentifier: sectionIdentifier,
            createdBy: user.username.split("_")[1]
        }, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Add document question API returned an error: ${res.status}`);
        }

        return res.data;
    }

    async getQuestion(questionIdentifier: string): Promise<DocumentQuestionDTO> {
        let headers = await this.setDefaultHeaders();

        const res = await this.http.get<DocumentQuestionDTO>(`question/${questionIdentifier}`,{
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Get document question API returned an error: ${res.status}`);
        }

        return res.data;
    }

    async removeDocumentQuestion(documentIdentifier: string, questionIdentifier: string): Promise<string> {
        let headers = await this.setDefaultHeaders();

        const res = await this.http.delete<string>(`document/${documentIdentifier}/question/${questionIdentifier}`, {headers: headers}); 
        
        if (res.status != 200) {
            throw new Error(`Remove question API returned an error: ${res.status}`);
        }

        return res.data;

    }

    async updateAnswer(documentIdentifier: string, questionIdentifier: string, answer: string, sectionIdentifier: string): Promise<string> {
        console.log('Updating');
        let headers = await this.setDefaultHeaders();
        const res = await this.http.put(`document/${documentIdentifier}/question`, {
            questionIdentifier: questionIdentifier,
            answer: answer,
            sectionIdentifier: sectionIdentifier
        }, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Update answer API returned an error: ${res.status}`);
        }

        console.log('Done');

        return res.data;
    }

    
    async updateQuestion(questionProps:UpdateQuestionProps): Promise<DocumentQuestionDTO> {
        const user = await Auth.currentUserInfo();
        let headers = await this.setDefaultHeaders();

        const res = await this.http.put<DocumentQuestionDTO>(`document/${questionProps.documentIdentifier}/question/${questionProps.questionIdentifier}`,
        {...questionProps, updatedBy: user.username.split("_")[1]} ,
        {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Update document question API returned an error: ${res.status}`);
        }

        return res.data;
    }


    async updateMetadata(metadataProps: UpdateMetadaProps): Promise<string> {
        let headers = await this.setDefaultHeaders();
        const res = await this.http.put(`document/${metadataProps.documentIdentifier}/metadata`, metadataProps, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Update metadata API returned an error: ${res.status}`);
        }

        console.log('Updated');
        console.log(res.data);

        return res.data;
    }

    async updateStatus(documentIdentifier: string, status: string): Promise<string> {
        const user = await Auth.currentUserInfo();
        let headers = await this.setDefaultHeaders();
        const res = await this.http.put(`document/${documentIdentifier}/status`, {
            status: status,
            updatedBy: user.username
        }, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Update status API returned an error: ${res.status}`);
        }

        return res.data;
    }

    async setDefaultHeaders(): Promise<AxiosRequestHeaders> {
        const session = await Auth.currentSession();

        const headers: AxiosRequestHeaders = {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`
        };

        return headers;
    }

    /**
     * Returns the user alias
     * @returns user alias
     */
    async getUserAlias(): Promise<string> {
        const userInfo = await Auth.currentUserInfo()

        return userInfo.username
    }

    /**
     * Returns user permissions
     * @returns user permissions
     */
    async getUserPermissions(): Promise<UserPermission> {
        let headers = await this.setDefaultHeaders();
        let alias = await this.getUserAlias();

        const res = await this.http.get<UserPermission>(`permissions/${alias}`, {
            headers: headers
        });

        if (res.status != 200) {
            throw new Error(`Get User Permission API returned an error: ${res.status}`);
        }

        return res.data
    } 
}

export default new ContentLibraryAdminApi();