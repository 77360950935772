import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { Auth, Hub } from 'aws-amplify';

import Logo from '../assets/images/logo-orange-mi.png';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "15%",
    height: "100%"
  },
  tabButton: {
    color: theme.palette.primary.light,
    fontWeight: 600,
    marginLeft: "2rem !important",
    padding: "6px 8px !important",
    borderRadius: 0
  },
  leftTabButton: {
    color: theme.palette.primary.light,
    fontWeight: 600,
    padding: "6px 8px !important",
    borderRadius: 0
  },
  tabButtonSelected: {
    borderBottom: "1px solid white !important"
  },
  profile: {
    color: theme.palette.primary.light,
    fontWeight: 600,
    fontSize: 20
  },
  dark: {
    color: theme.palette.secondary.main
  },
  toolbar: {
    padding: "0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  navigation: {
    display: "flex"
  },
  appBar: {
    backgroundColor: "#232f3e !important",
    display: "flex !important",
    color: "#fff !important",
    flexDirection: "column !important",
    width: "100% !important",
    boxSizing: "border-box !important",
    padding: "0 calc((100vw - 100vw*0.9) / 2)"
  }
}));

export default function ElevateAppBar(props) {
  const storedToken = String(localStorage.getItem("token"))
  const [selectedTab, setSelectedTab] = useState("");
  const [user, setUser] = useState();
  const [token, setToken] = useState(
    storedToken !== 'null' ? storedToken : null
  );

  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setToken("grating...");
          getToken().then(userToken => setToken(userToken.idToken.jwtToken));
          break;
        case "signOut":
          setToken(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, [history.location.pathname, user]);

  function getToken() {
    return Auth.currentSession()
      .then(session => session)
      .catch(err => console.log(err));
  }

  function handleClick(route) {
    history.push(route);
    setSelectedTab(route);
  }

  React.useEffect(() => {
    async function currentUser() {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser) {
        setUser(authenticatedUser)
      }
    }
    currentUser();
  }, [])

  React.useEffect(() => {
    if (history.location.pathname === '/') {
      setSelectedTab('/app');
    } else {
      setSelectedTab(history.location.pathname);
    }
  }, [history.location.pathname])

  
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position='sticky' className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <img alt="Rfx Logo" className={classes.icon} src={Logo} />
            {token ? (
              <>
                <div className={classes.navigation}>
                  <Button className={`${classes.leftTabButton} ${selectedTab === "/app" ? classes.tabButtonSelected : ""}`} onClick={() => handleClick("/app")}>Home</Button>
                  <Button className={`${classes.tabButton} ${selectedTab === "/search" ? classes.tabButtonSelected : ""}`} onClick={() => handleClick("/search")}>Search</Button>
                  <Button className={`${classes.tabButton} ${selectedTab === "/document" ? classes.tabButtonSelected : ""}`} onClick={() => handleClick("/document")}>Autocomplete an RFX</Button>
                  {process.env["REACT_APP_ENABLE_CONTENT_LIBRARY"] === 'true' && (
                    <Button className={`${classes.tabButton} ${selectedTab.includes("/content-library/home")  ? classes.tabButtonSelected : ""}`} onClick={() => handleClick("/content-library/home")}>CONTENT LIBRARY</Button>
                  )}
                  
                  <Button className={`${classes.tabButton} ${selectedTab === "/support" ? classes.tabButtonSelected : ""}`} onClick={() => handleClick("/support")}> REQUEST LEGAL SUPPORT</Button>
                </div>
                <span className={classes.profile}> Welcome, <span className={classes.dark}> {user && user.attributes.given_name} {user && user.attributes.family_name} </span> </span>
              </>
            ) : (
              <span></span>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  );
}