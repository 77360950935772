import React from 'react'

type VerticalDotIconProps = {
    fill?: string,
    height?: number,
    width?: number
}

const VerticalDotsIcon = ({fill="#232F3E", height=22, width=22}: VerticalDotIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
            <path fill={fill} d="M9.5 13a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z"/>
        </svg>
    )
}

export default VerticalDotsIcon