import React from "react";
import {
  FormControlProps
} from "react-bootstrap";

import searchImage from "../../../search/images/search-image.svg";
import "./search.scss";

export interface SearchProps {
  onSubmit: (queryText: string, pageNumber?: number) => void;
  pagination?: boolean;
}

interface SearchState {
  queryText: string;
}

export default class SearchComponent extends React.Component<
  SearchProps,
  SearchState
> {
  constructor(props: SearchProps) {
    super(props);

    this.state = {
      queryText: ""
    };
  }

  /*
   If you run into typing issues for the event type, trying switching the on change line with this
   onChange = (event: React.FormEvent<FormControlProps>) => {
  */
  onChange = (
    event: React.ChangeEvent<FormControlProps & HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    this.setState({ ...this.state, [target.name]: target.value });
  };

  onSearch = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // callback to the API call
    const { onSubmit } = this.props;
    if (this.props.pagination) {
      onSubmit(this.state.queryText, 1);
    } else {
      onSubmit(this.state.queryText);
    }
    
  };

  onButtonSearch = () => {
    const { onSubmit, pagination } = this.props;
    if (pagination) {
      onSubmit(this.state.queryText, 1);
    } else {
      onSubmit(this.state.queryText);
    }
   
  };


  render() {
    const {queryText} = this.state;
    return (
      <form
        onSubmit={this.onSearch}
        aria-label="form"
        className="search-form"
      >
        <div className="search">
          <img src={searchImage}/>
          <input value={queryText} onChange={this.onChange} name="queryText" type="text" placeholder="Enter a query here"/>
        </div>
      </form>
    );
  }
}
