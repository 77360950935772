import React from 'react';
import contentLibraryApi from "../../services/contentLibraryAdminApi"
import { UserPermission } from '../../models/permission';
import { Subtract } from 'utility-types'
import Spinner from '../spinner/spinner'

export type injectedPermissionProps = {
    userPermissions: UserPermission
}

type withPermissionState = {
    userPermissions: UserPermission
    isFetchingPermissions: boolean
}

const withPermissions = <P extends injectedPermissionProps>(
    Component: React.ComponentType<P>
) => 
    class WithPermissions extends React.Component<
        Subtract<P, injectedPermissionProps>,
        withPermissionState
    > {
        state: withPermissionState = {
            userPermissions: {
                alias: '',
                permissions: ''
            },
            isFetchingPermissions: true
        }

        /**
         * When component mounts, check if user roles are stored within localStorage.
         * If not, fetch them and store them in localStorage. 
         */
        async componentDidMount() {
            const userRoles = localStorage.getItem("userRoles")
            const currentDate = new Date()
            if (userRoles) {
                const {alias, permissions, expireDate} = JSON.parse(userRoles)
                if (currentDate > expireDate) {
                    await this.setUserPermissions()
                } else {
                    this.setState({
                        userPermissions: {
                            alias: alias,
                            permissions: permissions
                        },
                        isFetchingPermissions: false
                    })
                }
            } else {
                await this.setUserPermissions()
            }
        }

        async setUserPermissions() {
            const {alias, permissions} = await contentLibraryApi.getUserPermissions()
            const currentDate = new Date()
            const expireDate = new Date(currentDate).setHours(currentDate.getHours() + 6)
            localStorage.setItem("userRoles", JSON.stringify({
                alias: alias,
                permissions: permissions,
                expireDate: expireDate
            }))
            this.setState({
                userPermissions: {
                    alias: alias,
                    permissions: permissions
                },
                isFetchingPermissions: false
            })
        }

        render() {
            const {isFetchingPermissions} = this.state
            return(
                isFetchingPermissions ? <Spinner/> :
                <Component 
                    {...this.props as P}
                    userPermissions={this.state.userPermissions}
                />
            )
        }
    }



export default withPermissions