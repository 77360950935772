import { createAsyncThunk } from "@reduxjs/toolkit";
import contentLibraryAdminApi from "../../../content-library/services/contentLibraryAdminApi";


export const listDocuments = createAsyncThunk(
    "documents/list",
    async(_, {rejectWithValue}) => {
        try {
            const documents = await contentLibraryAdminApi.listDocuments()
            return documents
        } catch(err) {
            return rejectWithValue(err)
        }
    }
)

export const createDocument = createAsyncThunk(
    "documents/create",
    async(documentTitle:string, {rejectWithValue}) => {
        try {
            const document = await contentLibraryAdminApi.createDocument(documentTitle)
            return document
        } catch(err) {
            console.log(err)
            return rejectWithValue(err)
        }
    }
)

