import React, { ChangeEvent, useState } from 'react';
import {Select, MenuItem, SelectChangeEvent, FormControl, TextField} from '@mui/material'
import "./documentDetails.scss"
import Spinner from '../spinner/spinner'
import EditIcon from '../../icons/EditIcon'
import DocumentTitleEdit from '../editTitle/editTitle'
import { updateStatus, updateDocumentName } from '../../../store/actions/content-library/documentViewActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {DocumentDTO} from '../../models/document'

export type DocumentDetailsProps = {
    document: DocumentDTO;
    isAllowedToMakeChanges: boolean
}

const StatusToDisplayText = new Map<string,string>([
    ['in-review','In-Review'],
    ['approved', 'Approved'],
    ['expired', 'Expired']
])

/**
 * Returns a component that holds document details such as: Owners, Last Updated and Status
 */
const DocumentDetails = ({document: {name,ownerOne,ownerTwo,lastUpdated,status}, isAllowedToMakeChanges}: DocumentDetailsProps) => {
    const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false)
    const [documentStatus, setDocumentStatus] = useState<string>(status)

    const dispatch = useAppDispatch()

    const isUpdating = useAppSelector((state) => state.documentViewReducer.isUpdating)
    const document = useAppSelector((state) => state.documentViewReducer.document)

    const handleStatusChange = async (event: SelectChangeEvent<string>) => {
        if (await dispatch(updateStatus({
            documentIdentifier: document.documentIdentifier,
            status: event.target.value
        }))) {
            setDocumentStatus(event.target.value)
        }
    }

    const handleDocumentNameSubmit = async(name:string) => {
        if (await dispatch(updateDocumentName({
            documentIdentifier: document.documentIdentifier,
            ownerOne: document.ownerOne,
            ownerTwo: document.ownerTwo,
            industry: document.industry,
            segment: document.segment,
            name: name
        }))) {
            setIsEditingTitle(false)
        }
    }



    return(
        <>
            <div className="document-details-wrapper">
                <div className='document-title-wrapper'>
                    <h2>{name}</h2>
                    {isAllowedToMakeChanges && (
                        <div className='edit-name-container' onClick={() => setIsEditingTitle(true)}>
                            <EditIcon dataTestId={"edit-title"}/>
                        </div>
                    )}
                </div>
                <div className="document-details-item">
                    <h2>Owners</h2>
                    <h4>{`${ownerOne}${ownerTwo && ", " + ownerTwo}`}</h4>
                </div>
                <div className="document-details-item">
                    <h2>Last updated</h2>
                    <h4>{new Date(lastUpdated).toISOString().substring(0,10)}</h4>
                </div>
                <div className="document-details-item">
                    <h2>Status</h2>
                    {isAllowedToMakeChanges ? (
                    <div className='document-status-container'>
                        <form className='select-container'>
                            <Select
                                className={documentStatus == 'in-review' ? 'bg-yellow pill-select' : documentStatus == 'approved' ? 'bg-green  pill-select' : 'bg-red pill-select'}
                                id="status-select"
                                value={documentStatus}
                                onChange={handleStatusChange}
                                displayEmpty
                                sx={{
                                    '& legend': {display: 'none'},
                                    '& fieldset': {top: 0}
                                }}
                            >   
                                <MenuItem value={'in-review'}>In-Review</MenuItem>
                                <MenuItem value={'approved'}>Approved</MenuItem>
                                <MenuItem value={'expired'}>Expired</MenuItem>
                            </Select>
                        </form>
                        {isUpdating && <Spinner isButton={true}/>}
                    </div>
                    ):
                    <h4>{StatusToDisplayText.get(status)}</h4>
                }
                </div>
            </div>
            <DocumentTitleEdit
                open={isEditingTitle}
                label={"Edit document title"}
                title={name}
                isUpdating={isUpdating}
                onSubmit={handleDocumentNameSubmit}
                onClose={() => setIsEditingTitle(false)}
            />
        </>
    )
}

export default DocumentDetails

