import { Component, } from "react";
import { Button } from "@mui/material";
import './documentList.scss';
import SearchComponent from "../../components/search/search";
import Spinner from "../../components/spinner/spinner"
import DocumentListItem from "../../components/documentListItem/documentListItem"
import SearchResultsBar from "../../components/searchResultsBar/searchResultsBar"
import withPermissions, {injectedPermissionProps} from "../../components/withPermissions/withPermissions"
import { DocumentListState as DocumentListReduxState, search, resetRedirect } from '../../../store/slices/content-library/documentListSlice'
import { listDocuments, createDocument } from '../../../store/actions/content-library/documentListActions'
import { RootState } from "../../../store/store";
import { compose } from "redux";
import { connect } from "react-redux";
import CreateDocumentDialog from "../../components/addDialog/addDialog";

const mapStateToProps = (state:RootState) => {
    return {
        documents: state.documentListReducer.documents,
        isCreatingDocument: state.documentListReducer.isCreatingDocument,
        redirectTo: state.documentListReducer.redirectTo,
        isLoading: state.documentListReducer.isLoading,
        fetchError: state.documentListReducer.fetchError
    }
}

const mapDispatchToProps = {
    listDocuments,
    search,
    resetRedirect,
    createDocument
}

interface DocumentListProps extends injectedPermissionProps, DocumentListReduxState{
    listDocuments: typeof listDocuments,
    search: typeof search,
    resetRedirect: typeof resetRedirect,
    createDocument: typeof createDocument
}

type DocumentListState = {
    NewDocumentDialogOpen: boolean;
    NewDocumentName: string;
    IsFetchingDocuments: boolean;
    IsCreatingDocument: boolean;
}

class DocumentListView extends Component<DocumentListProps, DocumentListState> {
    state: DocumentListState = {
        NewDocumentDialogOpen: false,
        NewDocumentName: '' ,
        IsFetchingDocuments: false,
        IsCreatingDocument: false
    };

    componentDidMount() {
        this.props.listDocuments()
    }

    componentDidUpdate(prevProps:DocumentListProps, prevState:DocumentListState) {
        if (prevProps.isCreatingDocument !== this.props.isCreatingDocument) {
            if (!this.props.isCreatingDocument){
                this.setState({NewDocumentDialogOpen: false})
            }
            if (this.props.redirectTo) {
                this.props.resetRedirect()
                window.open(this.props.redirectTo)
            }
        }
    }

 
    openNewDocumentDialog = async () => {
        this.setState({
            NewDocumentDialogOpen: true,
        })
    }

    handleDialogClose = () => {
        this.setState({
            NewDocumentDialogOpen: false,
        })
    }

    openDocumentView(document:string) {
        const urlPath = document.toUpperCase().replace(new RegExp(' ', 'g'), '');
        window.open(`./home/${urlPath}`);
    }

    render() {
        const {isCreatingDocument, documents, isLoading, userPermissions: {permissions}} = this.props
        return (
            <div className="contentLibrary">
                <div className="leftGutter">
                    {permissions === "admin" && 
                        <Button color="primary" className="gutterButton" onClick={this.openNewDocumentDialog}>
                            + ADD NEW DOCUMENT
                        </Button>
                    }
                </div>
                <div className="main">
                    <h2 className="document-list-title">
                        RFx Sage Content Library
                    </h2>
                    <SearchComponent onSubmit={this.props.search} />
                    {!isLoading && documents && <SearchResultsBar results={documents} handleSort={() => {}}/>}
                    {isLoading && <Spinner/>}
                    {!isLoading && 
                        <div className="docResultWrapper">
                            {Array.isArray(documents) && documents.map((document, index) => {
                                return <DocumentListItem 
                                            key={index} 
                                            name={document.name} 
                                            documentIdentifier={document.documentIdentifier}
                                            segment={document.segment}
                                            industry={document.industry}
                                            lastUpdated={new Date(document.lastUpdated)} 
                                            onClick={this.openDocumentView}
                                        />
                            })}
                        </div>
                    }
                </div>
                <CreateDocumentDialog
                    open={this.state.NewDocumentDialogOpen}
                    onClose={this.handleDialogClose}
                    onSubmit={this.props.createDocument}
                    isUpdating={isCreatingDocument}
                    placeholder={"New document name"}
                    label={"Add new document"}
                />
            </div>
        )
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions
    )(DocumentListView)
