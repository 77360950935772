import React from "react";
import Kendra from "aws-sdk/clients/kendra";

import { isNullOrUndefined, selectMostRecentUpdatedTimestamp } from "../utils";

import ResultTitle from "./components/ResultTitle";
import ResultText from "./components/ResultText";
import ResultFooter from "./components/ResultFooter";
import "../search.scss";
import { Relevance } from "../constants";

interface DocumentResultsProps {
  results: Kendra.QueryResultItemList;
  snackbarOpen: boolean;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;

  openResultDialog: (
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;

  launchDocumentView: (
    resultItem: Kendra.QueryResultItem
  ) => void;
}

interface DocumentResultsState {
  snackbarOpen: boolean;
}

export default class DocumentResults extends React.Component<
  DocumentResultsProps,
  DocumentResultsState
> {
  constructor(props: DocumentResultsProps) {
    super(props);

    this.state = {
      snackbarOpen: false,
    };
  }

  private handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false
    })
  };

  private showSnackbar(message: string) {
    this.setState({
      snackbarOpen: true,
    })
  }

  // All results in this component has QueryResultType === "ANSWER"
  private renderResults = (result: Kendra.QueryResultItem) => {
    const { submitFeedback, launchDocumentView } = this.props;

    let attributes = Object();
    if (!isNullOrUndefined(result.DocumentAttributes)) {
      result.DocumentAttributes!.forEach(attribute => {
        attributes[attribute.Key] = attribute.Value;
      });
    }

    const lastUpdated = selectMostRecentUpdatedTimestamp(attributes);

    return (

      <div className="container-body" key={result.Id}>
        <ResultTitle
          queryResultItem={result}
          attributes={attributes}
          submitFeedback={submitFeedback}
          launchDocumentView={launchDocumentView}
        />
        <ResultText
          className="small-margin-bottom"
          text={result.DocumentExcerpt!}
          lastUpdated={lastUpdated}
        />
        <ResultFooter
          queryResultItem={result}
          attributes={attributes}
          submitFeedback={submitFeedback}
          openResultDialog={this.props.openResultDialog}
          launchDocumentView={launchDocumentView}
          showSnackbar={this.showSnackbar}
        />
      </div>

    )

  //   return (
  //     <Card variant="outlined" key={result.Id}>
  //       <CardContent>
  //         <div className="answerWrapper">
  //           <div className="answer">
  //             <ResultTitle
  //               queryResultItem={result}
  //               attributes={attributes}
  //               submitFeedback={submitFeedback}
  //             />
  //             <ResultText
  //               className="small-margin-bottom"
  //               text={result.DocumentExcerpt!}
  //               lastUpdated={lastUpdated}
  //             />
  //           </div>
  //           
  //         </div>
  //         <ResultFooter
  //           queryResultItem={result}
  //           attributes={attributes}
  //           submitFeedback={submitFeedback}
  //         />
  //       </CardContent>
  //       <Snackbar
  //         open={this.state.snackbarOpen}
  //         autoHideDuration={3000}
  //         message='Copied'
  //         onClose={this.handleSnackbarClose}
  //       />
  //     </Card>
  //   );
  };

  render() {
    const { results } = this.props;
    if (isNullOrUndefined(results) || results.length === 0) {
      return null;
    }

    return (
      <div className="document-results-section">
        {results.map(this.renderResults)}
      </div>
    );
  }
}
